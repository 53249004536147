<template>
	<v-row justify="center">
	  <v-dialog
		v-model="dialog"
		persistent
		width="900px">	  	

			<v-form @submit.prevent="updateClientProfile" ref="entryForm">
				{{ client.Industry }}
				<v-card class="modal-con update-modal">		
					<v-row class="modal-row">
						<v-col class="modal-col" cols="4" sm="6" md="6">
							<v-card-title class="p-0 modal-title-con">
								<h5 class="modal-title">{{ title }}</h5>
							</v-card-title>
						</v-col>
					</v-row>		
					<v-card-text class="modal-content-con p-0">	
						<v-row class="modal-row">
							<v-col class="modal-col" cols="12" sm="6" md="6">
								<v-text-field
									label="Name*"
									:rules="nameRules"
									v-model="localClient.Name"
									variant="underlined"
									clearable
									:clear-icon="'mdi-close'"
								></v-text-field>
							</v-col>

							<v-col cols="12" sm="6" md="6" class="modal-col">
								<v-text-field
									label="Details"
									v-model="localClient.Details"
									variant="underlined"
									clearable
									:clear-icon="'mdi-close'"
								></v-text-field>
							</v-col>

							<v-col cols="12" sm="6" md="6" class="modal-col">
								<v-text-field
									label="Company Site URL"
									:rules="urlRules"
									v-model="localClient.Company_Site_URL"
									variant="underlined"
									clearable
									:clear-icon="'mdi-close'"
								></v-text-field>
							</v-col>
							<v-col cols="12" sm="6" md="6" class="modal-col">
								<v-text-field
									label="Company LinkedIn Page"
									:rules="urlRules"
									v-model="localClient.Company_LinkedIn_Page"
									variant="underlined"
									clearable
									:clear-icon="'mdi-close'"
								></v-text-field>
							</v-col>
							<v-col cols="12" sm="6" md="6" class="modal-col">
								<v-text-field
									label="CRM Link"
									:rules="urlRules"
									v-model="localClient.CRM_Link"
									variant="underlined"
									clearable
									:clear-icon="'mdi-close'"
								></v-text-field>
							</v-col>
							<v-col cols="12" sm="6" md="6" class="modal-col">
								<Dropdown 
									class="w-100" 
									:disabled="false" 
									:items="industryOptions" 
									:value="localClient.Industry" 
									@item-selected="onIndustryChange" />
							</v-col>
							<v-col cols="12" sm="6" md="6" v-if="serverError">
								<div class="servererror">{{serverError}}</div>
							</v-col>
						</v-row>
					</v-card-text>

				<!-- https://github.com/RomainSimon/vue-simple-search-dropdown?tab=readme-ov-file -->
				
				<v-card-actions class="modal-actions p-0">		
					<v-spacer></v-spacer>
					<div class="button-group">
						<button 
							class="action-btn text-btn font-roboto text-decoration-none text-uppercase"
							variant="text"
							@click="dialogClosed"
							plain
							:disabled="!this.$root.userdetail.user[0].PhoneNumber"
							>
							CLOSE
						</button>
						<button 
							class="action-btn text-btn text-uppercase"
							variant="text"
							type="submit"
							plain				
						>
						SAVE
						</button>
					</div>
					
				</v-card-actions>
				</v-card>
			</v-form>
	  </v-dialog>
	</v-row>
  </template>

<script>
import { ref } from 'vue';
//import { VueTelInput } from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'
import timezonelist from '../timezone.json'
//import VueSelect from 'vue3-select-component'
import 'vue3-select-component/dist/style.css' 
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import Dropdown from '@/components/common/Dropdown.vue';

import { VDialog, VBtn, VCard, VRow, VCardTitle, VCardText, VTextField, VCardActions, VCol, VSpacer, VForm } from 'vuetify'
import userService from '@/services/userService';


const option = ref("");


export default {
	props: ['title', 'TogglePopup', 'ProfileSaved', 'Notification', 'Receiver', 'meg_notice', 'client'],
	components : {
		VDialog,  VCard, VRow,  VCardTitle, VCardText, VTextField, VCardActions, VCol, VSpacer, VForm, Dropdown
	},
	beforeMount() {
		//this.timezoneObject();
		//this.defaultSelectedTimeZone();	
	},
	mounted(){	
		this.getUserProfileData();
	},
	data() { return {
		token: localStorage.getItem("tknds") || '',
		dialog: true,
		tabclicked: false,
		errors:{},
		alertMessage: '',
		initialPhoneNumber: '',
		userEmail: this.$root.userdetail.user[0].Email ? this.$root.userdetail.user[0].Email :'',
		industryOptions: [
            { label: 'Construction', value:"Construction" },
            { label: 'Property Management', value:"Property Management" },
			{ label: 'Field Services', value:"Field Services" },
			{ label: 'Other', value:"Other" }
        ],
		nameRules: [
			v => !!v || 'Name is required'
		],
		urlRules: [
			v => !v || /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/.test(v) || 'Please enter a valid URL',
		],
		localClient: { ...this.client }
		//selectedCountries: ['IN', 'JP', 'US', 'CA', 'PL', 'PH', 'UY', 'CH', 'CZ', 'RS', 'VN', 'PT', 'GB', 'NL', 'FR', 'HR', 'UA', 'ME']
	}},
	methods : {		
		async getUserProfileData() {
			this.dataTabData = await userService.getUserProfileData(this.user?.UserID);
		},
		onIndustryChange(e){
			this.VueTelInput.Industry = e.value;
		},
		timezoneObject(){
			//format: { label: 'A Wizard of Earthsea', value: 'wizard_earthsea' }
			for (let todo of this.tz_list) {
				let obj = {}
				obj.label = todo.text
				obj.value = todo.utc[0]
				this.tzObj.push(obj);
			}
		},
		findUserTimezone(data){
			if( JSON.stringify(data).indexOf(this.userTimezone) > -1 ) {
				return data;
			}			
		},
		defaultSelectedTimeZone(){
			if(this.userTimezone != ''){
				this.defaultSelectedTz = this.tz_list.find(this.findUserTimezone); // return object			
			}
			if(this.defaultSelectedTz.text){
				
				if(this.$root.userdetail.user[0].TimeZone == '' || this.$root.userdetail.user[0].TimeZone == undefined){
					this.selectedTimezone = this.defaultSelectedTz.utc[0];
				}else{
					this.selectedTimezone = this.$root.userdetail.user[0].TimeZone;
				}
				//this.selectedTimezone = this.defaultSelectedTz.text;
			}
		},
		isActive(path) {
            return ref(this.$router.currentRoute).value.path === path
        },

		async updateClientProfile(e){
			let formValidated = await this.$refs.entryForm.validate();
			
			if(formValidated.valid){
				let data = {
					Name: this.localClient.Name,
					Details: this.localClient.Details,
					Company_Site_URL: this.localClient.Company_Site_URL,
					Company_LinkedIn_Page: this.localClient.Company_LinkedIn_Page,
					CRM_Link: this.localClient.CRM_Link,
					Industry: this.localClient.Industry,
					ClientID: this.localClient.ClientID
				}
				fetch(process.env.VUE_APP_APIDOMAIN+'/saveclientprofile', {
					method: "POST",
					body: JSON.stringify(data),
					headers: {
					"content-type": "application/json",
					"Authorization": "Bearer "+this.token
					}
				}).then(response => response.json())
					.then(result => {
						if(result.AuthSuccess == 0){
							this.serverError = result.message
							this.$root.logout();													           
						}
						if (!result.success) {
						// there was an error...
							this.serverError = result.message
						} else {
							this.error = "";						
							this.ProfileSaved(this.user);						
						}
				});
			} 
			
		},
		dialogClosed(e){
			this.dialog = false;
			if (typeof this.TogglePopup != "undefined"){
				this.TogglePopup();
			}
			
		},
	}
}
</script>

<style lang="scss">
	.phone-input-pupup {
		& .v-field-label:not(.v-field-label--floating){
      margin-top:-20px;
    } 
	}
		

	.tab-profile {
		padding: 76px 0;
	}

	
 
	.v_notification {
	width: 100%;
	background: #D6FCC4;
	padding: 20px 23px 20px 27px;
	font-size: 14px;
	border-radius: 10px;
	font-size: 14px;
	line-height: 16.41px;
	color: #333333;
	min-height: 80px;
	display: flex;
	align-items: center;
	position: relative;
	top: 10px;
	margin-top: -10px;
}

	 

	.servererror{
			width: 100%;
			background: red;
			padding: 15px;
			border-radius: 10px;
	}
	

	.modal-actions {
		display: flex;
		@media screen and (max-width: 599px) {
			flex-direction: column;
			gap: 10px;
		}
	}

	.button-group {
		display: flex;
		align-items: center;
		gap: 40px;
		@media screen and (max-width: 599px) {
			gap: 20px;
		}
		& .action-btn{
			color: #2F80ED;
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
			span{
				opacity: 1 !important;
			}
		}
	}



	.v-input.error--text {
		color: #B10000 !important;
        caret-color: #B10000 !important;
    	.v-input__control {
			.v-text-field__details > .error--text{
				color: #B10000 !important;
				caret-color: #B10000 !important;
			}
			.v-input__slot{
				&:before{
					color: #B10000 !important;
					caret-color: #B10000 !important;
					border-width:2px 0 0;
				}
				&:after{
					color: #B10000 !important;
					caret-color: #B10000 !important;
					border-width:2px 0 0;
				} 
				.v-text-field__slot > .error--text{
					color: #B10000 !important;
					caret-color: #B10000 !important;
				}
      		}
    	}
    }
	.v-field__overlay{
		background-color: white !important;
	}
	.custom-underlined-field .v-input__control {
		border-color: green;
	}
	.v-application .primary--text {
		color: #1976d2 !important;
		caret-color: #1976d2 !important;
	}

	.vue-tel-input{
		border:0px !important;
	}
	.vti__input{
		display:none !important;
	}
 

	.vti__dropdown-list {
		border: 1px solid #e4e4e7;
		border-radius: 4px;
		padding: 8px 0;
		width: 100%;
			&.below {
				top: 100%;
				height: 122px !important;
				margin-top: 1px;
			}
		}

	.vti__dropdown {
		padding: 0 4px 0 0;
		position: initial;
		&.open, &:hover {
			background-color: #fff;
		}
		
		& .vti__dropdown-item {
			padding: 8px 12px;
			display: flex;
			align-items: center;
			gap: 6px;
			font-size: 14px;
			gap: 6px;
			&.highlighted {
				background-color: #dbeafe;
			}
		}
	}

	 
	.v-tabs--density-default {
		--v-tabs-height: 26px !important;
	}

	.v-tab.v-tab.v-btn {
		color: #000;
		font-size: 18px;
		font-style: normal;
		font-weight: 500;
		line-height: 18px;
		letter-spacing: 0.8px;
		text-transform: capitalize;
		height: 25px !important;
		min-width: 0 !important;
		margin-top: 14px;
		margin-left: 28px;
	}

	.v-btn--slim {
		padding: 0!important;
	}

	.disabled1{
		color:#d7e7fd !important;
		pointer-events: none;
	}

	.v-card {
		overflow: hidden;
	}

	.v-window {
		overflow: initial !important; 
	}

	.menu{
		height: 180px;
		margin: 1px 0 0 0 !important;
		& .menu-option{
			 color: #333333;
			 font-size: 13px;
			 line-height: 16.41px;
			 font-style: normal;
			 font-weight: 400;
		}
	}
 
 
	
.timez_p {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #333333;
    font-size: 14px;
    line-height: 16.41px;
    font-style: normal;
    font-weight: 400;
		margin-bottom: 115px;
		height: 201px;
    & .tz_lable {
        margin-bottom: 24px;
        text-align: center;
        letter-spacing: 0;
				margin-top: 80px;
    }
    & .vue-select {
        width: 100%;
        max-width: 400px;
        border-radius: 10px;
        & .control {
            min-height: 80px;
            background: #F2F2F2;
            border: none;
            border-radius: 10px;
            padding: 0 12px 0 27px;
						&.focused {
							box-shadow: none;
						}
        }
        & .value-container {
            padding: 0;
        }
        & .indicators-container {
            padding: 0;
        }
        & .dropdown-icon {
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
            & path {
                fill: #7B8794;
            }
        }
        & .single-value {
					color: #333333;
					font-size: 14px;
					line-height: 16.41px;
					font-style: normal;
					font-weight: 400;
					height: 100%;
        }
    }
}

 


.update-modal {
	 
		& .together{
    	min-width: 0
    }
		& .notof_tze {
			background-color: #E3F1FF;
			width: 100%;
			padding: 20px 23px 20px 27px;
			font-size: 14px;
			border-radius: 10px;
			font-size: 14px;
			line-height: 16.41px;
			color: #333333;
			min-height: 80px;
			align-items: center;
			position: relative;
			top: 10px;
			margin-top: -10px;
		}
		& .notof_01 {
			/* background-color:#E3F1FF; */
			background-color:#344D7A;
			color:#FFFFFF;
		}
}

 



	.deactive_tz{
		color:#e5e5e5 !important;
	}

 
 
	.clear-button{
		display: none !important;
	}

	.update-modal .email_c{
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 16px;
			color: #000000;
	}
	.update-modal .v-tab {
		cursor: pointer !important;
		transition: none !important;
	}
	.update-modal .v-tab:hover {
		background-color: transparent !important;
	}
	.update-modal .v-ripple__container {
		display: none !important;
	}
	.tabs_D {
		background-color: red;
	}

	.tabs_D_item .v-btn__overlay{
		background-color: transparent !important;
	}
	.tabs_D_item .v-btn__content{
		padding-bottom: 8px;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 16px;
		color: #000000;
		width: 100%;
	}
 

	.tabs_D_item .v-tab__slider{
		height:4px !important;
	}

	.update-modal .v-tab.v-tab.v-btn{
		margin-top: 0px;
	}
	
	.mdi-close {
		font-size: 24px !important;
		color: black !important;
		opacity:1 !important;
	}

	.vti__flag {
		margin-left: 0;
	}
	

</style>