<template>
  <div class="align-center justify-center super-admin-table">
      <div class="selected_row_box">
      <!-- <div class="d-flex justify-content-center position-relative">
        <div class="bulk_select">
          <label>3 Selected</label>
          <label class="link"><IconArchieve /><span>Archieve</span></label>              
        </div>
      </div> -->
    </div>
     <S2gTable
       :indeterminate="indeterminate"
       :items="clients"
       :selectedItemCount="selectedContacts"
       ref="tableRef"
       :isWhiteIcons="true"
       :headers="clientHeader"
       :filterItems="[
         { label: 'All', value: 'all' },
         { label: 'None', value: 'none' },
       ]"
       :batchAction="false"
       @toggle-row-select="selectMembers"
       :searchText="search"
       :thsearch="false"
       @search="searchTeams"
       @open-search="searchOpen"
       @refresh="onResetClicked"
       :showCount="true"
       :isReload="true"
       :totalItems="pager.totalItems"
       :totalCountText="'Clients'"
       :totalCountText1="'Contact'"
       :dynamicSearchHeight="false"
       :isSelectGlobalCheckBox="isSelectAll"
       @select-all="selectAll"
       :disabledColumn="{first_col:false}"
       :page="(currentPage - 1) * itemsPerPage"
     >
       <template v-slot:table_body="{ item }">
         <td>
           <div>{{ item.item.Name }}</div>
         </td>
         <td>
          <div>{{ item.item.Name }}</div>
          <!-- <div class="filter-cell"><span></span> {{ item.item.companyName }}</div> -->
         </td>
         <td>
          <div>{{ getFormattedDate(item.item.Created) }}</div>
         </td>
         <td>
            <div>{{ getFormattedDate(item.item.Modified) }}</div>
         </td>
          
         <td style="max-width: 210px; width: 210px">
           <div class="table-actions">
            <ClientPreview class="setting-icon" value="true" :content="item.item"/>
             <!-- <SettingDots/> -->
             <span class="icon-btn" @click="onClientSelected(item.item)">
               <!--  <router-link class="action-button" :to="{ name: 'ourcontents', query:{id: item.item.ContentID } }"> -->
                <IconView/>
              <!-- </router-link> -->
               
             </span>
             <span class="icon-btn" @click="editClient = item.item; TogglePopup('buttonTrigger')">
               <EditIcon/>
             </span>
             <span class="icon-btn" :class="{ disabled: this.$root.userIsSuperAdmin()}">
               <IconArchieve/>
             </span>
           </div>
         </td>
       </template>
     </S2gTable>
     
   </div>

   <Paginaiton v-if="pager.totalPages > 1" 
          :currentPage="currentPage"
          :totalItems="pager.totalItems"
          :itemsPerPage = "itemsPerPage"
          :totalPages = "pager.totalPages"
          @page-changed="changePage"
        />
   
        <UpdateClientProfile 
            v-if="popupTriggers.buttonTrigger" :client="editClient" :title="'Client'"
            :TogglePopup="() => TogglePopup('buttonTrigger')" :ProfileSaved="(data) =>ProfileSaved()" >
        </UpdateClientProfile>

</template>

<script >
 
 
import S2gTable from "@/components/common/S2gTable.vue";
import Paginaiton from '@/components/common/Paginaiton.vue';
//import SettingDots from '@/components/icons/message_settings/SettingDots.vue';
import EditIcon from '@/components/icons/EditIcon';
import IconView from '@/components/icons/IconView';
import IconArchieve from '@/components/icons/IconArchieve';
import UpdateClientProfile from '@/components/UpdateClientProfile.vue'; 
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import ClientPreview from '@/components/common/ClientPreview.vue'
const API_URL = process.env.VUE_APP_APIDOMAIN;

export default {
  name: "clients",
  components: {S2gTable, EditIcon, IconView, IconArchieve, Paginaiton, UpdateClientProfile, ClientPreview},
  setup () {
        const popupTriggers = ref({
            buttonTrigger: false
        });		
        const TogglePopup = (trigger) => {
            popupTriggers.value[trigger] = !popupTriggers.value[trigger]
        }
        const router = useRouter();
        return {
            UpdateClientProfile,
            popupTriggers,
            TogglePopup,
            router,
        }
    },
  data : () =>({
    clients:[],
    pager: {},
    currentPage: 1,
    itemsPerPage: 10,
    isProfileOpen: false, 
    editClient:false,
    clientHeader: [
     { key: "name", title: "Name",},
     {
       key: "companyName",
       title: "Company Name",
       class: "pr-0",
       isFilter: false,
       filterItems: [
         { label: 'All', value: 'all' },
         { label: 'None', value: 'none' },
         { label: 'Pending', value: 'pending' },
         { label: 'Verified', value: 'verified' },
         { label: 'Invite', value: 'invite' },
       ],
     },
     {
       key: "signUpDate",
       title: "Sign up date",
       class: "pr-0",
       isFilter: false,
       filterItems: [
         { label: 'All', value: 'all' },
         { label: 'None', value: 'none' },
         { label: 'Pending', value: 'pending' },
         { label: 'Verified', value: 'verified' },
         { label: 'Invite', value: 'invite' },
       ],
     },
     {
       key: "lastUsed",
       title: "Last used",
       class: "pr-0",
       isFilter: false,
       filterItems: [
         { label: 'All', value: 'all' },
         { label: 'None', value: 'none' },
         { label: 'Pending', value: 'pending' },
         { label: 'Verified', value: 'verified' },
         { label: 'Invite', value: 'invite' },
       ],
     },
     { key: "details", title: "Details" },
   ],
   search: '',
   token: localStorage.getItem("tknds") || '',
  }),
  mounted() {
    this.$store.commit("setOnBehalf", false);
    this.$store.commit("setOnBehalfUser", {'ClientID':null, 'UserID': null});
    this.getData();
  },
  methods :{
    getData(){
        let url = `${API_URL}/getclients?page=${this.currentPage}&perpage=${this.itemsPerPage}&search=${this.search}`;
        //alert(url)
          fetch(url, { 
              method: 'GET', 
              headers: {
                "content-type": "application/json",
                "Authorization": "Bearer "+this.token
              } 
            })                      
              .then(response => response.json())
              .then(async (result) => {
                //Auth Token Failed     
                console.log(result);   
                if(result.AuthSuccess == 0){
                      //this.serverError = result.message
                      this.$root.logout();                      							           
                }else{
                  this.pager = result.pager;
                  this.clients = result.clients;
                }                 
          })
    },
    getFormattedDate(dateString){
      if(dateString){
        const date = new Date(dateString);
        const formattedDate = date.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        });
        return formattedDate
      } 
      return dateString;
    },
    changePage(page){
      this.currentPage = page;
      this.getData();
    },
    ProfileSaved(data){
      this.popupTriggers.buttonTrigger = false;
      this.onResetClicked();
      
    },
    searchTeams(e){
      this.currentPage = 1;
      this.search = e;
      this.getData();
    },
    searchOpen(e){},
    onResetClicked(){
      this.currentPage = 1;
      this.search = '';
      this.getData();
    },
    onClientSelected(client){
      this.$store.commit("setOnBehalf", true);
      this.$store.commit("setOnBehalfUser", {'ClientID':client.ClientID, 'UserID': null});
      this.$router.push('/messages');
    },
    toggleMenu() {
        this.isMenuOpen = !this.isMenuOpen;
    },
  }
}


/* 

const clientHeader= [
     { key: "name", title: "Name",},
     {
       key: "companyName",
       title: "Company Name",
       class: "pr-0",
       isFilter: false,
       filterItems: [
         { label: 'All', value: 'all' },
         { label: 'None', value: 'none' },
         { label: 'Pending', value: 'pending' },
         { label: 'Verified', value: 'verified' },
         { label: 'Invite', value: 'invite' },
       ],
     },
     {
       key: "signUpDate",
       title: "Sign up date",
       class: "pr-0",
       isFilter: false,
       filterItems: [
         { label: 'All', value: 'all' },
         { label: 'None', value: 'none' },
         { label: 'Pending', value: 'pending' },
         { label: 'Verified', value: 'verified' },
         { label: 'Invite', value: 'invite' },
       ],
     },
     {
       key: "lastUsed",
       title: "Last used",
       class: "pr-0",
       isFilter: false,
       filterItems: [
         { label: 'All', value: 'all' },
         { label: 'None', value: 'none' },
         { label: 'Pending', value: 'pending' },
         { label: 'Verified', value: 'verified' },
         { label: 'Invite', value: 'invite' },
       ],
     },
     { key: "details", title: "Details" },
   ]

 let clients = [{
    name : "Client Name",
    companyName: "Red Feather Property Management LLC.",
    signUpDate: "Sep 17, 2024",
    lastUsed: "Nov 28, 2024",
    },
    {
      name : "Client Name",
      companyName: "Property Management Company",
      signUpDate: "Sep 17, 2024",
      lastUsed: "Nov 28, 2024",
    },
    {
      name : "Client Name",
      companyName: "Property Management Company",
      signUpDate: "Sep 17, 2024",
      lastUsed: "Nov 28, 2024",
    },
    {
      name : "Client Name",
      companyName: "Property Management Company",
      signUpDate: "Sep 17, 2024",
      lastUsed: "Nov 28, 2024",
    },
    {
      name : "Client Name",
      companyName: "Property Management Company",
      signUpDate: "Sep 17, 2024",
      lastUsed: "Nov 28, 2024",
    },
    {
      name : "Client Name",
      companyName: "Property Management Company",
      signUpDate: "Sep 17, 2024",
      lastUsed: "Nov 28, 2024",
    },
    {
      name : "Client Name",
      companyName: "Property Management Company",
      signUpDate: "Sep 17, 2024",
      lastUsed: "Nov 28, 2024",
    },
    {
      name : "Client Name",
      companyName: "Property Management Company",
      signUpDate: "Sep 17, 2024",
      lastUsed: "Nov 28, 2024",
    },
    {
      name : "Client Name",
      companyName: "Property Management Company",
      signUpDate: "Sep 17, 2024",
      lastUsed: "Nov 28, 2024",
    },
    {
      name : "Client Name",
      companyName: "Property Management Company",
      signUpDate: "Sep 17, 2024",
      lastUsed: "Nov 28, 2024",
    }
  ]

 let indeterminate  = false;

 function selectMembers(e){

 }

 let isSelectAll = false; */
</script>

<style lang="scss">
  .super-admin-table {
    padding-top: 78px;
    position: relative;
    & .mr-70 {
      margin-right: 125px !important;
      }
    & .search_container, .selected_row_box {
      margin-bottom: 0;
      position: absolute;
      top: 9px;
      width: 100%;
    }
  }
</style>
