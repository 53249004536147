<template>
  <v-app id="app">	
      <div>   
        <notifications />
        <Header :user="userdetail"/>
        <div class="app-container full-screen">
          <router-view :user="userdetail"></router-view>
        </div>
      </div>
      <div class="app-container">
        <Footer/>	
      </div>
    <AccountDeactivatedModal :context="isAccountDeactivatedContext" v-model="isAccountDeactivatedAlert" @closed="isAccountDeactivatedAlert = false" />
  </v-app>
</template>


<script>
//import VueMaterial from 'vue-material'
//import 'vue-material/dist/vue-material.min.css'
//import 'vue-material/dist/theme/default.css'
import Header from './components/common/Header.vue';
import Footer from './components/common/Footer.vue';
import './assets/icon.css';
import AccountDeactivatedModal from './components/modals/AccountDeactivatedModal.vue';

export default {
    props:["loggedInUser", "userdetail", "to"],
    components: {Header, Footer, AccountDeactivatedModal},
    name: 'app',
    methods: {
        isPath (path) {
           return this.$route.path === path
        },
    },
    mounted(){
      this.$store.commit('setUser',this.userdetail);
      // if(this.to){
      //   this.$router.push(this.to);
      // }
      window.addEventListener('showAccountDeactivatedAlert',(e)=>{
        this.isAccountDeactivatedContext = e.detail?.context;
        this.isAccountDeactivatedAlert = true;
      })
    },
    data: () => {
      return {
        isAccountDeactivatedAlert: false,
        isAccountDeactivatedContext: "",
      }
    }

}
</script>

<style scoped>
@font-face {
    font-family: Roboto;
    src: url('assets/fonts/Roboto-Regular.ttf');
}
.v-btn{
  text-transform:none !important;
  
}
body .v-application{
    background:#f2f2f2 !important;
}
.gray-bg{
  background:#f2f2f2;
}
</style>
