import { createApp, provide, computed } from 'vue'
import App from './App.vue'
import { h } from 'vue'
import { ref } from 'vue';
//import VueRouter from 'vue-router';
import { createRouter, createWebHistory, useRouter, useRoute } from 'vue-router';

import vuetify from './plugins/vuetify';
import VueGoogleMaps from "@fawmi/vue-google-maps" // Import package
//import {VueMaterial} from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
//import 'vue-material/dist/theme/default.css'

import { loadFonts } from './plugins/webfontloader'
import firebase from 'firebase/compat/app';
import 'firebaseui/dist/firebaseui.css'


import 'firebase/compat/auth';
import { config } from './helpers/firebaseConfig'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle';
//import '@mdi/font/css/materialdesignicons.css'

import AuthView from './views/AuthView.vue';
import HomeView from './views/HomeView.vue';
import Auth from './components/Auth.vue';
import AuthNotAllowed from './components/AuthNotAllowed.vue';
import AuthLogout from './components/AuthLogout.vue'
import ComposeMessage from './views/ComposeMessage/ComposeMessage.vue';
import DownloadApp from './views/DownloadApp/DownloadApp.vue'
import ContactsView from './views/contacts/ContactsView.vue';
import MessageView from './MessageView.vue';
import TermsPrivacyView from './views/TermsPrivacyView.vue';

import 'vuetify/dist/vuetify.min.css';
import eventBus from './eventBus';
import './assets/scss/main.scss';
import Group from './views/Group/Group.vue';
import Admin from './views/Admin/Admin.vue';
import Account from './views/Account/Account.vue';
import Activity from './views/Activity/Activity.vue';
import Clients from './views/Clients/Clients.vue';
import Archive from './views/Archive/Archive.vue';
import './assets/messages.css';
import Notifications from '@kyvg/vue3-notification'
import { createStore, useStore } from 'vuex';
import userService from './services/userService';
import { globalState } from '@/globals.js';
import { InvitationStatusType, Roles } from './Constants';
import SignupTokenInvalid from './components/SignupTokenInvalid.vue';

//loadFonts()

const store = createStore({
	state() {
		return {
			user: {},
			isFirstTime: false,
			isOnBehalf: localStorage.getItem('isOnBehalf') == 'true' || false,
			onBehalfUser: JSON.parse(localStorage.getItem('onBehalfUser')) || {}
		}
	},
	mutations: {
		setUser(state, user) {
			state.user = user;
		},
		setFirstTime(state, isFirst) {
			state.isFirstTime = isFirst;
		},
		setOnBehalf(state, onBehalf){
			state.isOnBehalf = onBehalf;
			localStorage.setItem('isOnBehalf', onBehalf);
		} ,
		setOnBehalfUser(state, onBehalfUser){
			state.onBehalfUser = onBehalfUser;
			localStorage.setItem('onBehalfUser', JSON.stringify(onBehalfUser));
		} 
	},
	getters: {
		user(state) {
			return state.user;
		},
		isFirstTime(state) {
			return state.isFirstTime
		},
		isOnBehalf(state) {
			return state.isOnBehalf
		},
		onBehalfUser(state) {
			return state.onBehalfUser
		} 
	}
})

let user = computed(() => store.getters.user);

const routes = [
	{ path: "/", component: HomeView, name: "/", meta: { title: "Sig2 Operator Console" } },
	{ path: "/messages", component: MessageView, name: "ourcontents", meta: { title: "Messages", role: [Roles.AccountHolder, Roles.SaasOperator] } },
	{ path: "/messages/editor", component: ComposeMessage, name: "ComposeMessage", meta: { title: "Message Editor", role: [Roles.AccountHolder, Roles.SaasOperator] } },
	{ path: '/login', component: AuthView, name: 'login', meta: { title: "Login" } },
	{ path: '/logout', component: AuthLogout, name: 'logout', meta: { title: "Logout" } },
	{ path: '/signup/:status(cancelled|expired)', component: SignupTokenInvalid, name: 'signup-status', meta: { title: "Signup Status" } },
	{ path: '/authnotallowed', component: AuthNotAllowed, name: 'authnotallowed', meta: { title: "Auth Not allowed" } },
	{ path: "/contacts", component: ContactsView, name: "contacts", meta: { title: "Contacts", role: [Roles.AccountHolder, Roles.SaasOperator] } },
	{ path: "/download-app", component: DownloadApp, name: "DownloadApp", meta: { title: "DownloadApp" } },
	{ path: "/group", component: Group, name: "Group", meta: { title: "Groups", role: [Roles.AccountHolder, Roles.SaasOperator] } },
	{ path: "/terms", component: TermsPrivacyView, name: "Terms", meta: { title: "Terms" } },
	{ path: "/privacy", component: TermsPrivacyView, name: "Policy", meta: { title: "Policy" } },
	{
		path: '/:pathMatch(.*)*',
		redirect: '/',
	},
	{
		path: "/admin",
		component: Admin,
		name: "Admin",
		meta: { title: "Admin", role: [Roles.AccountHolder, Roles.SaasOperator] },
		beforeEnter: (to, from, next) => {
			const waitForUserValue = () => {
				if (user.value) {
					if (user.value?.AccountHolder || user.value?.SaasAdmin) {
						next();
					} else {
						next({ name: 'ourcontents' });
					}
				} else {
					setTimeout(waitForUserValue, 50); // Check again after 50ms
				}
			};

			waitForUserValue();
		}
	},
	{ path: "/account", component: Account, name: "Account", meta: { title: "Account" } },
	{ path: "/activity", component: Activity, name: "Activity", meta: { title: "Activity" } },
	{ path: "/clients", component: Clients, name: "Clients", meta: { title: "Clients" } },
	{ path: "/archive", component: Archive, name: "Archive", meta: { title: "Archive" } },
]



const router = createRouter(
	{ routes, history: createWebHistory(), },
);

// router.beforeEach((to, from, next) => {
// 	if(!user) return;
// 	if (to.meta.role) {
// 		const requiredRoles = to.meta.role;
// 		if (user && requiredRoles.some(role=>user[role]==1)) {
// 			next();
// 		} else {
// 			if(user[Roles.SaasAdmin]) {
// 				next({name: 'Admin'})
// 			} else if(user[Roles.AccountHolder]) {
// 				next({name: "Account"})
// 			}else if(user[Roles.SaasOperator]){
// 				next({name:'ourcontents'})
// 			} else {
// 				next({name:'authnotallowed'})				
// 			}
// 		}
// 	} else {
// 		// next();
// 	}
// });

createApp({
	App,
	el: '#app',
	data: () => ({
		loggedInUser: "",
		unauthorizeuser: false,
		loggedout: false,
		toRoute: null,
		isSignupTokenValid: true ,
		signupTokenStatus: null,
	}),
	methods: {
		logout(showLogoutPage = true) {
			let app = this;
			firebase.auth().signOut().then(function () {
				// Sign-out successful.
				// Clear local storage and session storage
				localStorage.clear();
				sessionStorage.clear();

				// Clear Vuex state
				store.commit("setUser", null);

				app.loggedInUser = "";
				app.userdetail = {};
				app.uiooo = {};
				app.unauthorizeuser = false;
				app.loggedout = showLogoutPage;
				//app.$router.push('/auth')
			}, function (error) {
				alert("Errot in logging out, please try again")
				console.log(+ error)
				// An error happened.
			});
		},
		getAuthData() {
			return firebase;
		},
		setOperatorRadiusUnit() {
			let instance = this;
			fetch('https://ipinfo.io/json?token=0d62e40c4be101')
				.then(function (response) {
					return response.json(); // Parse the response body as JSON
				})
				.then(function (data) {
					localStorage.setItem("operator_radius_unit", data.country == "US" ? 1 : 2)
					localStorage.setItem("operator_radius", data.country == "US" ? 1609.344 : 1000)
				})
				.catch(function (error) {
					console.error('Error:', error);
				});
		},
		userIsSuperAdmin() {
			const user = this.$store.getters.user;
            //return globalState.isSuperAdmin;
            return (user?.SupportAdmin || 
                    user?.SuperAdmin) &&
                    !user?.AccountHolder &&
                    !user?.SaasAdmin &&
                    !user?.SaasOperator
        }
	},
	setup() {
	},
	created: async function () {
		//console.log('fresh deploy!')
		//alert('start')
		const urlParams = new URLSearchParams(window.location.search);
		const token = urlParams.get('token');
		var thisapp = this;
		if (token) {
			let tokenCheckResponse = await userService.getInvitationStatus(token);
			if(tokenCheckResponse.type!=InvitationStatusType.ACTIVE){
				thisapp.isSignupTokenValid = false				
			}
			if(tokenCheckResponse.type==InvitationStatusType.CANCELLED){
				thisapp.signupTokenStatus = "cancelled"
			}
			if(tokenCheckResponse.type==InvitationStatusType.EXPIRED){
				thisapp.signupTokenStatus = "expired"
			}
		}
		if(thisapp.isSignupTokenValid){
			this.setOperatorRadiusUnit();
			firebase.initializeApp(config);
			firebase.auth().onAuthStateChanged((user) => {
				if (user) {
					if (localStorage.getItem('justLoggedIn') == null) {
						localStorage.setItem('justLoggedIn', 'true');
					}
					fetch(process.env.VUE_APP_APIDOMAIN + "/isuserallowed?email=" + encodeURIComponent(user.email), {
						method: "GET",
						headers: {
							"content-type": "application/json"
						}
					}).then(response => response.json())
						.then(result => {
							let checkUser = result.user[0];
							if (result.user.length > 0 && checkUser?.UserActive && !checkUser?.IsDeleted && checkUser?.SaasAdmin != null && checkUser?.SaasOperator != null
								&& checkUser?.AccountHolder != null
							) {
								// Set the Login Auth Toke After User Login
								if (result.token) {
									// Set the Auth Token
									localStorage.setItem("tknds", result.token);
								}
								thisapp.loggedInUser = 'Guest User';
								if (result.user[0].FirstName) {
									thisapp.loggedInUser = result.user[0].FirstName
								}
								if (result.user[0].LastName) {
									thisapp.loggedInUser += ' ' + result.user[0].LastName;
								}
								//First Login ?
								thisapp.userdetail = result;
								console.log("thisapp.userdetail = ", thisapp.userdetail)
								//thisapp.setOperatorRadiusUnit();
								thisapp.unauthorizeuser = false;
								let currentroute = ref(this.$router.currentRoute).value.path;

								// if (!result.user[0].PhoneNumber && currentroute.indexOf('messages') == -1){
								// 		alert("St1-"+result.user[0].PhoneNumber)
								// 		thisapp.userdetail.firsttimeuser = true;
								// 		thisapp.$router.push('/contacts');
								// }  

								/********************************************************/
								//if (!result.user[0].PhoneNumber && localStorage.getItem('justLoggedIn') === 'true'){
								//console.log('this.$store.getters :: ', this.$store.getters)
								//alert('check')
								if (result.user[0]?.SaasOperator || this.$store.getters.isOnBehalf === true) {
									if (!result.user[0].PhoneNumber) {
										thisapp.userdetail.firsttimeuser = true;
										//thisapp.$router.push('/contacts');
										thisapp.$router.push('/messages');
									} else {
										// Have to do this because on reload we have a delay before the router initializes
										// as we have to check role for admin page. This is only a quickfix for not redirecting 
										// to other page when reloading admin page.
										// It was working perfect for all other pages but for admin route wasn't working coz of slight
										// delay in initializing that router because of beforeEnter hook.
										setTimeout(() => {
											if (ref(this.$router.currentRoute).value.path == "/") {
												thisapp.$router.push('/messages');
											}
										}, 500);
									}
								} else if (result.user[0]?.AccountHolder || result.user[0]?.SaasAdmin) {
									thisapp.$router.push('/admin');
								} else if (result.user[0]?.SupportAdmin || result.user[0]?.SuperAdmin){
									thisapp.$router.push('/clients');
								}
								/*********************************************************/

								//thisapp.$router.push('/messages');
							} else {
								//This is a new User
								console.log('This is a new User!')

								userService.getInvitation(user.email).then(async (invitedUser) => {
									let firstName = "";
									let lastName = "";
									// let invitedUserName = `${invitedUser?.FirstName} ${invitedUser?.LastName}`;
									if (invitedUser != "") {
										[invitedUser] = invitedUser;
										firstName = invitedUser?.FirstName;
										lastName = invitedUser?.LastName;
										let dispName = `${firstName} ${lastName}`
										if (dispName != user.displayName) {
											await user.updateProfile(user, {
												displayName: dispName,
											})
										}
									} else {
										let dispName = user.displayName.split(" ");
										[firstName, lastName] = dispName;
									}
									if (firstName == undefined) firstName = "";
									if (lastName == undefined) lastName = "";

									thisapp.unauthorizeuser = true;
									let userData = {};
									userData.SSOType = (user.providerData[0].providerId == "google.com") ? 'Google' : 'Email';
									userData.LastName = '';
									userData.FirstName = '';
									userData.Email = userData.UserName = user.email
									userData.FirstName = firstName;
									userData.LastName = lastName;

									// if (result.user.length > 0){
									// 	console.log(result);
									// 	console.log('InActive User')
									// 	thisapp.userdetail = result;
									// 	thisapp.unauthorizeuser = true;
									// 	thisapp.loggedInUser = userData.FirstName +' '+userData.LastName;
									// 	thisapp.$router.push('/authnotallowed')
									// } else {
									console.log('First time user');
									fetch(process.env.VUE_APP_APIDOMAIN + '/savesaasuser', {
										method: "POST",
										body: JSON.stringify(userData),
										headers: {
											"content-type": "application/json"
										}
									}).then(response => response.json())
										.then(result => {
											console.log('user data saved!');
											if (result.success) {
												fetch(process.env.VUE_APP_APIDOMAIN + "/isuserallowed?email=" + encodeURIComponent(userData.Email), {
													method: "GET",
													headers: {
														"content-type": "application/json"
													}
												}).then(response => response.json())
													.then(result => {
														if (result.user.length > 0 && result.user[0].UserActive) {
															// Set the Login Auth Toke After User Login
															if (result.token) {
																// Set the Auth Token
																localStorage.setItem("tknds", result.token);
															}
															thisapp.unauthorizeuser = false;
															thisapp.loggedInUser = 'Guest User';
															if (result.user[0].FirstName) {
																thisapp.loggedInUser = result.user[0].FirstName
															}
															if (result.user[0].LastName) {
																thisapp.loggedInUser += ' ' + result.user[0].LastName;
															}
															//thisapp.userdetail = result;

															thisapp.userdetail = result;
															thisapp.userdetail.firsttimeuser = true;
															//console.log('New  User ::', result)
															// thisapp.$router.push('/contacts');
															// //console.log('user signup successful');
															// if(result.user[0]?.AccountHolder || result.user[0]?.SaasAdmin) {
															// 	thisapp.$router.push('/admin');
															// }

															if (result.user[0]?.SaasOperator) {
																thisapp.$router.push('/messages');
															} else if (result.user[0]?.AccountHolder || result.user[0]?.SaasAdmin) {
																thisapp.$router.push('/admin');
															} else if (result.user[0]?.SupportAdmin || result.user[0]?.SuperAdmin){
																thisapp.$router.push('/clients');
															}
														} else {
															thisapp.userdetail = result;
															thisapp.unauthorizeuser = true;
															thisapp.loggedInUser = userData.FirstName + ' ' + userData.LastName;
															thisapp.$router.push('/authnotallowed')
														}
													})
											} else {
												//console.log(result)
												//console.log('user signup failed');
												thisapp.unauthorizeuser = true;
												thisapp.$router.push('/authnotallowed')
											}
										});

								});

								// }

							}

						});

				} else {
					let currnt_route = ref(this.$router.currentRoute).value.path;
					if (currnt_route != '/terms' && currnt_route != '/privacy' && currnt_route != '/login') {
						this.$router.push('/');
					} else {
						this.$router.push(currnt_route);
					}
				}
			});
		}
	},
	render() {
		store.commit("setUser", this.userdetail?.user?.at(0));
		store.commit("setFirstTime", this.userdetail?.firsttimeuser);
		if(!this.isSignupTokenValid) {
			this.$router.push(`/signup/${this.signupTokenStatus}`)
			return h(SignupTokenInvalid)
		}
		if (localStorage.getItem('justLoggedIn') == null && !this.loggedout) {
			let currnt_route = ref(this.$router.currentRoute).value.path;
			if (currnt_route == "/login") {
				return h(AuthView);
			}
			return h(HomeView);
		}
		if (!!this.loggedInUser && !this.unauthorizeuser) {
			this.userdetail.user[0].timezone = this.userdetail.user[0].TimeZone;
			this.userdetail.user[0].Phone = this.userdetail.user[0].PhoneNumber;
			console.log(this.loggedInUser, this.userdetail)
			return h(App, { loggedInUser: this.loggedInUser, userdetail: this.userdetail.user[0] })
		} else {
			let template = false;
			if (this.loggedout) {
				this.$router.push('/logout')
				template = AuthLogout;
			} else {
				//console.log('current route path ::',ref(this.$router.currentRoute).value.path);
				template = (this.unauthorizeuser && this.loggedInUser) ? AuthNotAllowed : HomeView;
			}
			return h(template, { loggedInUser: this.loggedInUser });
		}

	}
}).use(vuetify)
	.use(Notifications)
	.use(store)
	.use(router).use(VueGoogleMaps, {
		load: {
			key: "AIzaSyAr5X7eblwPkDDuB9Xvw8NSsOtzYuXYUOQ",
			libraries: "places"
		}
	}).provide('eventBus', eventBus).mount('#app')

var titlePrefix = "Sig2 Operator Console";
document.title = titlePrefix;
router.beforeEach((to, from, next) => {
	document.title = titlePrefix;
	localStorage.setItem("previousPage", from.path);

	if (typeof to.meta != "undefined" && typeof to.meta.title != "undefined") {
		document.title = titlePrefix + ' : ' + to.meta.title;
		//console.log('route for each')
	}
	if (to.path == "/") {
		document.title = titlePrefix;
	}
	if (to.path == "/clients" || to.path == "/archive") {
		globalState.isSuperAdmin = true;
	} else {
		globalState.isSuperAdmin = false;
	}

	speechSynthesis.cancel();
	next();

})




