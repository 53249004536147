import api from './api';

export default {
    users: [],

    async getInvitationStatus(token) {
        let response = await api.get(`/saas-user/invite/${token}`);
        return response.data;
    },

    async getUsersCount() {
        let response = await api.get("accountHolder/count");
        return response.data?.at(0);
    },

    async getUserProfileData(userId) {
        let response = await api.get(`saas-user/count/${userId}`);
        console.log('XXXXX === ', response);
        return response?.data?.data;
    },

    async getUserById(userId) {
        let res = await api.get(`saas-user/${userId}`);
        return res.data?.at(0);
    },

    async getAllUsers() {
        let response = await api.get('saas-user');
        this.users = response.data;
        return this.users;
    },

    async getFilteredOperators() {
        if (this.users == '') {
            return this.getOperators();
        }
        return this.users.filter((user) => !!user.SaasOperator);
    },

    async getOperators() {
        let response = await api.get("operators");
        return response.data;
    },

    async addUser(firstName, lastName, email, phone, isAdmin, isOperator, timezone) {
        const userData = {
            firstName,
            lastName,
            email,
            phone,
            isAdmin,
            isOperator,
            timezone
        };

        let response = await api.post('saas-user/invite', userData);
        return response.data;
    },

    async updateUser(firstName, lastName, email, phone, isAdmin, isOperator, timezone, userId) {
        const userData = {
            firstName,
            lastName,
            email,
            phone,
            isAdmin,
            isOperator,
            userId,
            timezone
        };

        let response = await api.post('user/edit', userData);
        return response.data;
    },

    async updateInvitation(firstName, lastName, email, phone, isAdmin, isOperator, inviteId) {
        const invitationData = {
            firstName,
            lastName,
            email,
            phone,
            isAdmin,
            isOperator,
            inviteId,
        };

        let response = await api.post('saas-user/invite/edit', invitationData);
        return response.data;
    },

    async deleteUser(userId) {
        let res = await api.delete(`/saas-user/${userId}`);
        return res.data;
    },

    async deleteInvitation(userId) {
        let res = await api.delete(`/saas-user/invitation/${userId}`);
        return res.data;
    },

    async transferData(from, to) {
        let res = await api.post("transfer", {
            operatorId: to,
            transferOperatorId: from,
        });
        return res.data;
    },

    async getAccountHolder() {
        let res = await api.get('/saas-user/get/account-Holder');
        return res.data;
    },

    async getInvitation(email) {
        let res = await api.get(`/saas-user/invitation?email=${encodeURIComponent(email)}`);
        return res.data;
    },

    async saveSaasClientSetting(shareContact, viewMessages) {
        let res = await api.patch(`/saas-client/setting?shareContact=${shareContact}&viewMessage=${viewMessages}`);
        return res.data;
    },

    async getSaasClientSetting() {
        let res = await api.get(`/saas-client/setting`);
        return res.data;
    },

    async getUserUsage() {
        let res = await api.get(`/saas-client/usage`);
        return res.data;
    },

    async getStripeToken() {
        let res = await api.post("/saas-client/payment-method");
        return res.data;
    },

    async getBillingUrl() {
        let res = await api.get("/saas-client/invoice");
        return res.data;
    },

    async getFreeTrialStatus() {
        let res = await api.get("/saas-client/free-trial");
        return res.data;
    },

    async activateAccount() {
        let res = await api.post("/saas-client/activate");
        return res.data;
    },

    async getNewProjectedPrice(projectedPrice, currentPrice, newMessageCount) {
        let res = await api.get(`/saas-client/project-price?ProjectedPrice=${projectedPrice}&currentPrice=${currentPrice}&newMessageCount=${newMessageCount}`);
        return res.data;
    },

    async updateMessages(newCount) {
        let res = await api.patch(`/saas-client/messages?messagesCount=${newCount}`)
        return res.data;
    },

    async transferAccount(toUserId) {
        let res = await api.post('/saas-client/transfer-ownership', {
            transferUserID: toUserId
        });
        return res.data;
    }
};