<template>
    
      <v-dialog
        v-model="dialog"
        persistent
        class="custom-message-settings"
      >
        <v-card>
          
          <v-card-title>
            <span class="dialog-title">Message Settings</span>
          </v-card-title>
          <button type="button"
              class="close-btn-icon"
              @click="onCloseWithoutSave"
            >
            <span class="outline">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.3642 7.05025L13.4144 12L18.3642 16.9497L16.95 18.364L12.0002 13.4142L7.05048 18.364L5.63627 16.9497L10.586 12L5.63627 7.05025L7.05048 5.63604L12.0002 10.5858L16.95 5.63604L18.3642 7.05025Z" fill="#7B8794"/>
                </svg>
            </span>
            <span class="outline_hover">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="3" y="3" width="18" height="18" rx="4" fill="#E0E0E0"/>
                <path d="M18.3642 7.05025L13.4144 12L18.3642 16.9497L16.95 18.364L12.0002 13.4142L7.05048 18.364L5.63627 16.9497L10.586 12L5.63627 7.05025L7.05048 5.63604L12.0002 10.5858L16.95 5.63604L18.3642 7.05025Z" fill="#7B8794"/>
                </svg>
            </span>
            </button>
          <v-card-text>
            <v-container>
                    <div class="d-flex align-items-center mb-22px">
                        <div class="d-flex align-items-center" style="width: 174px;">
                            <DurationIcon class="mr-5"/>&nbsp; <span class="custom-label">Duration</span>
                         </div>     
                         <Dropdown  :items="durationObj" :value="selectedDuration" @item-selected="onDurationSelected"/>
                    </div>

                    <div class="d-flex align-items-center mb-22px">
                        <div class="d-flex align-items-center">
                            <div class="d-flex align-items-center" style="width: 174px;">
                                <CalendarIcon  class="mr-5"/>&nbsp; <span class="custom-label">Start</span>
                            </div>     
                            <DatePicker @date-selected="onStartDateSelected" @custom-date-selected="onCustomDateSelected" :min="formatDate(new Date())" :selectedDate="selectedStartDate"/>
                        </div>
                        <div class="d-flex align-items-center" style="margin-left: 106px;">
                            <div class="d-flex align-items-center" style="width: 101px;">
                                <div class="custom-label mr-5" v-if="selectedDuration != 'No Limit'">End</div>
                            </div>     
                            <DatePicker v-if="selectedDuration != 'No Limit'" @date-selected="onEndDateSelected" @custom-date-selected="onCustomDateSelected" :min="formatDate(selectedStartDate)" :selectedDate="selectedEndDate"/>
                        </div>
                    </div>

                    <div class="d-flex align-items-center mb-22px">
                        <div class="d-flex align-items-center">
                            <div class="d-flex align-items-center" style="width: 174px;">
                                <RepeatIcon class="mr-5" />&nbsp; <span class="custom-label">Repeat</span>
                            </div>     
                            <Dropdown :disabled="selectedDuration == '1 Day' || selectedDuration == '1/2 Day'" :items="repeatObj" @item-selected="onRepeatSelected" :value="selectedRepeatOption"/>
                        </div>
                        <div class="d-flex align-items-center" style="margin-left: 106px;">
                            <div class="d-flex align-items-center" style="width: 101px;">
                                <span v-if="selectedRepeatOption == 'Custom'" class="custom-label">Days</span>
                            </div>     
                            <RepeatDays :availableDays="availableDays" v-if="selectedRepeatOption == 'Custom'" @repeat_days_updated="onRepeatDaysUpdated" :selectedDays="selectedRepeatDays"/>
                        </div>
                    </div>

                    <div class="d-flex align-items-center">
                            <div class="d-flex align-items-center" style="width: 174px;">
                                <TimeIcon class="mr-5" />&nbsp; <span class="custom-label">Time</span>
                            </div>     
                            <div class="a-slider" :class="{ flip: difference, unflip: !difference }"> 
                            <v-range-slider
                                :ticks="seasons"
                                :model-value="[0, 96]"
                                v-model="selectedTime"
                                min="0"
                                max="96"
                                :step="1"
                                strict
                                @start="onSliderDragStart"
                                @end="onSliderDragEnd"
                                show-ticks="always"
                                thumb-label="always"
                                thumb-size="16"
                                tick-size="0"
                                track-color="#b9b6b6"
                                track-fill-color="#828282"
                            >
                            <template v-slot:thumb-label="{ modelValue }">
                                <span class="custom-callout-range-slider" v-html="formatAMPM(modelValue * 15)"></span>
                            </template>
                            </v-range-slider>
                        </div>
                    </div>
                    
                    <div class="divider"></div>
                    

                

                
                    <div class="d-flex align-items-center justify-content-between">
                    <v-radio-group  v-model="msg_when" inline>
                        <div class="d-flex align-items-center">
                        <v-radio
                                color="#7B8794"
                                value="Arriving"
                            >
                            <template v-slot:label>
                                <span class="custom-label">Arriving</span>
                                <div v-bind:class="(msg_when == 'Arriving') ? 'custom-radio-selected' : 'custom-radio-not-selected'">
                                    <ArrivingIcon/>
                                </div>
                            </template>
                        </v-radio>
                        </div>
                        <div class="d-flex align-items-center">
                        <v-radio
                            color="#7B8794"
                            value="Leaving"
                        >
                        <template v-slot:label>
                            <span class="custom-label">Leaving</span>
                            <div v-bind:class="(msg_when == 'Leaving') ? 'custom-radio-selected' : 'custom-radio-not-selected'">
                                <LeavingIcon/>
                            </div>
                        </template>
                        </v-radio>
                    </div>
                    </v-radio-group>
                     </div>
                

              
            </v-container>

            
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              :ripple="false"
              color="blue-darken-1"
              class="close-btn text-btn btn"
              variant="text"
              @click="onCloseWithoutSave"
            >
                CANCEL
            </v-btn>
            <v-btn
              :disabled="this.$root.userIsSuperAdmin()"
              :ripple="false"
              color="blue-darken-1"
              variant="text"
              @click="onSaveSettings"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      
  </template>

<script setup>
    import { ref, defineEmits, defineProps, computed, watch, onMounted } from 'vue';
    import DurationIcon  from '../icons/message_settings/DurationIcon.vue';
    import CalendarIcon  from '../icons/message_settings/CalendarIcon.vue';
    import RepeatIcon  from '../icons/message_settings/RepeatIcon.vue';
    import TimeIcon from '../icons/message_settings/TimeIcon.vue';
    import ArrivingIcon from '../icons/message_settings/ArrivingIcon';
    import LeavingIcon from '../icons/message_settings/LeavingIcon';
    import Dropdown  from '../common/Dropdown.vue';
    import RepeatDays from "../common/RepeatDays.vue";
    import DatePicker from "../common/DatePicker.vue";
    import { useRoute, useRouter } from 'vue-router';

    let props = defineProps({
        showSettings:Boolean,
        _selectedDuration:String,
        _selectedStartDate:String,
        _selectedEndDate:String,
        _selectedRepeatOption:String,
        _selectedRepeatDays:String,
        _selectedTimeStart:String,
        _selectedTimeEnd:String,
        _msg_when:String,
        _custom_settings:Object
    })

    let msg_when = ref(props._msg_when);
    /* watch(()=>msg_when.value, newVal=>{
        if(newVal == "Leaving"){
            selectedDuration.value = "Custom";
        }
    }) */
    
    const UserInfoData = ref("");
    const value = ref([0, 1000]);
    
    const durationObj = [
            { 'ms':'86400000'     , 'label':'1 Day' },
            { 'ms':'172800000'    , 'label':'2 Days' },
            { 'ms':'259200000'    , 'label':'3 Days' },
            { 'ms':'604800000'    , 'label':'1 Week' },
            { 'ms':'1209600000'    , 'label':'2 Week' },
            { 'ms':'1814400000'    , 'label':'3 Week' },
            { 'ms':'-1'            , 'label':'No Limit'}
        ]
    let selectedDuration = ref(props._selectedDuration);
    watch(()=>selectedDuration.value, newVal=>{
        if(newVal == "1 Day"){
            selectedRepeatOption.value = "No"
        }
    })
    watch(
        ()=> props._selectedDuration, newVal=>{
            selectedDuration.value = newVal;
            //alert(isCustomDuration())
            /* if (!isCustomDuration()){
                let eventData = durationObj.find(o => o.label === newVal);
                onDurationSelected(eventData);
            } */
        },{deep: true}
    )
    const repeatObj = [
        {'label': 'No'},
        {'label': 'Daily'},
        {'label': 'Custom'}
    ] 

    const emits = defineEmits(['closed', 'setting-saved']);
    const router = useRouter();
    const route = useRoute();
    
    let dialog = computed({
            get() {
                if (props.showSettings && selectedDuration.value != "Custom"){
                    let eventData = durationObj.find(o => o.label === selectedDuration.value);
                    if(typeof eventData != "undefined" && typeof eventData.ms != "undefined"){
                        onDurationSelected(eventData);
                    }
                }
                return props.showSettings;
            },
            set(value) {emits('closed', value);}
    })
     let seasons = {}
     const seasonsArray = [
        '12:00 am',
        '','','','','','','','','','','','','','','','','','','','','','','',
        '06:00 am',
        '','','','','','','','','','','','','','','','','','','','','','','',
        '12:00 pm',
        '','','','','','','','','','','','','','','','','','','','','','','',
        '06:00 pm',
        '','','','','','','','','','','','','','','','','','','','','','','',
        '11:59 pm',
        ] 
        seasonsArray.forEach((val, index)=>{
            seasons[index] = val
        })
        function onSliderDragStart(){
            return false;
        }
        function onSliderDragEnd(){
           
            //will be used later
        }

        const availableDays = computed(()=>{
           
            const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
            if (selectedDuration.value == "No Limit"){
                return daysOfWeek;
            }
            const result = [];
            const start = new Date(selectedStartDate.value);
            const end = new Date(prevSelectedEndDate.value);
                  end.setDate(end.getDate() + 1);
            const diffTime = Math.abs(end - start);
            const numberOfDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

            let startDate = new Date(selectedStartDate.value);
            //alert(startDate)
            for (let i = 0; i < numberOfDays; i++) {
                const nextDate = new Date(startDate);
                nextDate.setDate(startDate.getDate() + (i));
                const dayName = daysOfWeek[nextDate.getDay()];
                result.push(dayName);
            }
            return result;
        })

        const difference = computed(() => {
            const r = Math.abs(selectedTime.value[0] - selectedTime.value[1]);
            return r < 20;
        });
        
        function formatAMPM(mins) {
            
            if(mins == 1440){
                return '11:59 PM'
            }

            let hours = "";
            let minutes = "";
            //let hours = Math.floor(mins / 60);
            if (String(mins).indexOf(":") != -1){
                hours = mins.split(":")[0];
                minutes = mins.split(":")[1];
            }else {
                hours = Math.floor(mins / 60);
                minutes = mins % 60;
            }

            let ampm = hours < 12 ? 'AM' : 'PM';
            hours = hours > 12 ? hours - 12 : hours;
            //minutes = mins % 60;
            /* if (hours === 12 && minutes === 0) {
                ampm = 'AM';
            } */
            if (hours == 0){
                hours = 12;
            }
            let timeString = hours + ':' + (minutes < 10 ? '0' : '') + minutes + ' ' + ampm;
            return timeString;
        }
        
        let selectedTime = ref([AmPmToMinutes(props._selectedTimeStart), AmPmToMinutes(props._selectedTimeEnd)]);
        let tempSelectedTime = [selectedTime.value[0], selectedTime.value[1]];
         watch(selectedTime, newVal=>{
            let diff = Math.abs(selectedTime.value[0] - selectedTime.value[1]);
            if (diff < 1){
                selectedTime.value = tempSelectedTime;
                return false;
            }
            tempSelectedTime = [selectedTime.value[0], selectedTime.value[1]];
         })
        function AmPmToMinutes(timeString) {
            let amPm = timeString.toLowerCase().indexOf('am') != -1 ? 'am' : 'pm';
            let theTime = timeString.toLowerCase().split('am').join('').split('pm').join('').split(' ').join('');
            let hours = 0;
            if (amPm == 'am' && theTime.split(':')[0] == 12){
                 hours =  0;
            } else if (amPm == 'pm' && theTime.split(':')[0] != 12){
                hours =  Number(theTime.split(':')[0]) + 12;
            } else {
                hours = Number(theTime.split(':')[0]);
            }
            //let hours = timeString.toLowerCase().indexOf('am') != -1 || theTime.split(':')[0] == 12 ) ? Number(theTime.split(':')[0]) : Number(theTime.split(':')[0]) + 12

            let minutes = hours * 60 + Number(theTime.split(':')[1])
            return minutes/15;
        }
        //Repeat Days
        let selectedRepeatDays = ref(props._selectedRepeatDays);
        
        let onRepeatDaysUpdated = function(data){
            selectedRepeatDays.value = data;
            //selectedDuration.value = "Custom";
        }

        let selectedRepeatOption = ref(props._selectedRepeatOption);
        let onRepeatSelected = function(val){   
            selectedRepeatOption.value = val.label;
            if (selectedRepeatOption.value != "No"){
                //selectedDuration.value = "Custom";
            }
        }

        //Duration
        let onDurationSelected = function(data){
                let startMS = new Date(formatDate(selectedStartDate.value)).getTime();
                // minus 1 MS so that start and end date for one day will be same 
                let endMS = startMS + Number(data.ms); 
                endMS = endMS -1;
                prevSelectedEndDate.value = formatDate(new Date(endMS).toString());
                selectedDuration.value = data.label;
        }

        function isHumanReadableDate(dateString) {
            const humanReadablePattern = /^[A-Za-z]{3} \d{2}, \d{4}$/;
            return humanReadablePattern.test(dateString);
        }
        function isISO8601(dateString) {
            const iso8601Pattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3})?Z$/;
            return iso8601Pattern.test(dateString);
        }

        function formatDate(dateString) {
            const options = { year: 'numeric', month: 'short', day: 'numeric'};
            let d = "";
            if (isISO8601(dateString)){
                d = new Date(dateString).getTime() + (new Date().getTimezoneOffset()*60*1000);
            } else {
                d = new Date(dateString).getTime();
            }
            let formattedDate = '';
            if (dateString){
                formattedDate = new Date(d).toLocaleDateString('en-US', options);
            } else {
                formattedDate = new Date().toLocaleDateString('en-US', options);
            }
            return formattedDate;
        }


        //Start Date
        let selectedStartDate = ref(formatDate(new Date().toString()));
        let onStartDateSelected = function(date){
            initialStartTime = false;
            selectedStartDate.value =  date;
            isCustomDuration();
        }
        //End Date
        let prevSelectedEndDate = ref(formatDate(new Date().toString()));
        let onEndDateSelected = function(date){
            selectedEndDate.value =  date;
            isCustomDuration();
        }
        let onCustomDateSelected = function(date){
           // selectedDuration.value = "Custom";
        }
        let selectedEndDate = computed({
            get(){
                //console.log(selectedEndDate.value)
                //alert(new Date(formatDate(selectedStartDate.value)).getTime()+' >= '+new Date(formatDate(prevSelectedEndDate.value)).getTime())
                     if(new Date(formatDate(selectedStartDate.value)).getTime() > new Date(formatDate(prevSelectedEndDate.value)).getTime()){
                       // alert(1)
                        let currentSelectedStartDate = new Date(formatDate(selectedStartDate.value));
                        currentSelectedStartDate.setDate(currentSelectedStartDate.getDate() + 1)
                        return currentSelectedStartDate;
                    } else { 
                        //alert(2+"|"+prevSelectedEndDate.value)
                        return prevSelectedEndDate.value;
                    }
            },
            set(val){
                prevSelectedEndDate.value = val; 
            }
        })


        let checkIfCustomSettings = function(){
           if (
               // selectedStartDate.value != formatDate(props._selectedStartDate)
                // || selectedEndDate.value != formatDate(props._selectedEndDate)
                isCustomDuration()
            ||  selectedRepeatOption.value != "No"
            || formatAMPM(selectedTime.value[0]*15) != "12:00 AM"
            || formatAMPM(selectedTime.value[1]*15) != "11:59 PM" 
            || msg_when.value != "Arriving"
            ) {
                //alert('custom')
                //selectedDuration.value = "Custom";
                return false;
            } 
            return true;
        }


        let isCustomDuration = function(){
            if (selectedDuration.value == 'No Limit'){
                return false;
            }
            const start = new Date(formatDate(selectedStartDate.value));
           // alert(start);
            const end = new Date(formatDate(prevSelectedEndDate.value));
            end.setDate(end.getDate() + 1)
            //alert(end)
            //alert(selectedStartDate.value + '  |||  ' + prevSelectedEndDate.value)
            const diffTime = Math.abs(end - start);
            //alert("diffTime ="+ diffTime)
            for(let obj of durationObj){
                if (diffTime == obj.ms){
                    selectedDuration.value = obj.label;
                    return false
                }
            }
            const diffDay = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            selectedDuration.value = diffDay == 0 ? '1 Day' : diffDay + ' Days';
            return true;
        }

        let onSaveSettings = function(){
            /* let defaultSettings = checkIfCustomSettings(); */
            //alert("save ::"+prevSelectedEndDate.value)
            let customSettings = {
                "Duration" : selectedDuration.value,
                "StartDate" : selectedStartDate.value,
                "EndDate" : prevSelectedEndDate.value,
                "RepeatOption" : selectedRepeatOption.value,
                "RepeatDays": selectedRepeatDays.value,
                "TimeStart" : formatAMPM(selectedTime.value[0]*15),
                "TimeEnd" : formatAMPM(selectedTime.value[1]*15),
                "When": msg_when.value,
                //"defaultSettings" : defaultSettings
                "defaultSettings" : false
            }
            emits('setting-saved', customSettings)
            dialog.value = false;
        }

        function mapVariables(){
            selectedDuration.value = props._selectedDuration
            selectedStartDate.value = formatDate(props._selectedStartDate);
            
            selectedEndDate.value = formatDate(props._selectedEndDate);
            //alert("map "+ props._selectedEndDate +"||"+ selectedEndDate.value)
            selectedRepeatOption.value = props._selectedRepeatOption
            selectedRepeatDays.value = props._selectedRepeatDays;
            selectedTime.value = [AmPmToMinutes(props._selectedTimeStart), AmPmToMinutes(props._selectedTimeEnd)];
            msg_when.value = props._msg_when
        }
        let onCloseWithoutSave = function(){
            //mapVariables()
            dialog.value = false;
        }
        watch(()=>props._custom_settings, newVal=>{
            mapVariables(newVal)
        },{deep:true})

        
/*         computedStartTime = computed({
            get() {
                if (formatDate(newVal.value) == formatDate(new Date())){
                    let starttime =  formatAMPM(getStartTimeForNewMessage())
                    retrun [AmPmToMinutes(starttime), AmPmToMinutes(props._selectedTimeEnd)]
                } else {
                    selectedTime.value = ref([AmPmToMinutes(props._selectedTimeStart), AmPmToMinutes(props._selectedTimeEnd)]);
                }
               
            },
            set(value) {}
        }) */
        let initialStartTime = true;
        watch(()=>selectedStartDate, newVal=>{
            if(formatDate(newVal.value) == formatDate(new Date()) && AmPmToMinutes(formatAMPM(getStartTimeForNewMessage())) < AmPmToMinutes(props._selectedTimeEnd)){
                let starttime =  formatAMPM(getStartTimeForNewMessage())
               selectedTime.value = [AmPmToMinutes(starttime), AmPmToMinutes(props._selectedTimeEnd)];
            }else{
                if (initialStartTime && typeof route.query.id != "undefined"){
                    selectedTime.value = [AmPmToMinutes(props._selectedTimeStart), AmPmToMinutes(props._selectedTimeEnd)];
                }else {
                    selectedTime.value = [AmPmToMinutes("12:00 AM"), AmPmToMinutes(props._selectedTimeEnd)];
                }
                
            }
            initialStartTime = false;
        },{deep:true})

        
        watch(()=>dialog.value, newVal=>{
            initialStartTime = newVal;
            //alert('watch :: '+ initialStartTime)
        })

        function roundToNearest15Minutes(date) {
            let minutes = date.getMinutes();
            let roundedMinutes = Math.floor(minutes / 15) * 15;
            // Adjust the date object accordingly
            if (roundedMinutes === 60) {
                date.setHours(date.getHours() + 1);
                date.setMinutes(0);
            } else {
                date.setMinutes(roundedMinutes);
            }
            return date;
        }
        function getStartTimeForNewMessage(){
            // Round off the current time to the nearest 15 minutes
            let roundedDate = roundToNearest15Minutes(new Date());
            // Format the rounded time as a string
            let hours = String(roundedDate.getHours()).padStart(2, '0');
            let minutes = String(roundedDate.getMinutes()).padStart(2, '0');
            if (roundedDate.getMinutes() == 0){
                 minutes = String(roundedDate.getMinutes()).padStart(1, '0');
            }
            return `${hours}:${minutes}`;
        }


</script>


<style lang="scss">

.v-selection-control-group--inline {
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
}
     .divider {
        background-color: #d1d1d1;
        margin-top: 60px;
        margin-bottom: 63px;
        display: block;
        height: 1px;

     }
    .custom-message-settings .v-overlay__content {
        width: 1000px !important;
        height: 800px;
        & .v-card {
            height: 800px;
            padding: 0 !important;
        }
    }
   .card-text-con{
        padding: 25px 10px 0 16px !important;
        .about-card-text{
            color: #000;
            font-family: 'Roboto';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 114.286%;
            .nav-link{
                color: #344D7A;
                font-size: 12px;
                font-weight: 500;
                line-height: 16px;
            }
        }
   }

   .custom-message-settings .form-control {
        padding: 5px 13px !important;
        height: 44px !important;
        border-radius: 5px !important;
        border: 1px solid #BDBDBD !important;
        background: #FFF !important;
    }
    .custom-message-settings .dropdown-btn{
        width: auto !important;
        min-width: 130px !important;
    }
   
   .v-toolbar-title .v-toolbar-title__placeholder{
        color: #000;
        font-family: 'Roboto';
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 1.2;
        letter-spacing: .01em;
   }
   .range-slider-tow{
        border-bottom: 1px solid #BDBDBD;
   }
   .custom-message-settings .v-card-title {
    line-height: 1 !important;
    padding: 25px 30px 0 !important;
   }
   .custom-message-settings .v-card {
    padding: 0 !important;
   }
   .custom-message-settings .dialog-title{
        font-size: 16px !important;
        font-weight: 500 !important;
        line-height: 1;
        color:#000;
   }
    .custom-message-settings .v-card-text {
        padding: 0px 0px 0px !important;
    }
     
    .v-range-slider{
        width:97% !important;
    }
    .custom-radio-selected{
        margin-left: 57px;
       /*  padding:20px; */
        border-radius: 5px;
        background-color: #F7F7F7;
        border:1px solid #CCCCCC;
    }
    .v-label {
        opacity: 1 !important
    }
    .custom-radio-not-selected{
        margin-left: 57px;
       /*  padding:20px; */
        border-radius: 10px;
        background-color: none;
        border:1px solid transparent;
    }
    .custom-message-settings .v-card-actions{
        margin-top:0px !important;
    }
    .custom-message-settings .v-container{
        padding: 112px 111px 0px 80px !important;
    }
    .custom-date-picker {
        transform: scale(0.8);
        transform-origin: top left;
        border: 1px solid gray !important;
    }
    .v-locale--is-ltr .v-slider.v-input--horizontal .v-slider-thumb__label, .v-locale--is-ltr.v-slider.v-input--horizontal .v-slider-thumb__label {
        background: white !important;
        border-radius: 5px !important;
        border:1px solid #7B8794 !important;
        color:#7B8794;
        height:30px !important;
    }
    .custom-callout-range-slider{
        font-size:11px;
        color: #7B8794;
        font-weight: 500; 
        text-align:center; 
        white-space:nowrap;
        padding:5px 0px;
    }
    
    .outline_hover {
        display: none;
        }

    .close-btn-icon {
        position: absolute;
        right: 18px;
        top: 15px;
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
            .outline_hover {
                display: block;
            }
            .outline {
                display: none;
            }
        }
    }
    .v-slider-thumb__surface{
        background: white !important;
        border:none !important;
    }
    .a-slider .v-slider-thumb{
        border:3px solid #828282 !important;
    }
    .v-slider-track__tick-label{
        font-size: 10px !important;
        text-transform: uppercase;
        font-weight: 600;
        line-height: 20px;
        margin-top: 14px !important;
        color: #7B8794;
    }
    .v-date-picker{
        border:1px solid #969494;
    }
    .custom-right-label{
        padding-left:60px !important;
        line-height: 40px !important;
    }
    .v-date-picker-month__day--hide-adjacent {
        //opacity: 0;
        display: none !important;
    }
    .custom-message-settings .custom-label{
        font-size: 16px !important;
        font-weight: 600 !important;
        color : #000 !important;
    }

    .v-selection-control-group--inline {
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
}
.v-selection-control__wrapper {
    display: inline-flex;
    margin-right: 16px;
}

.v-selection-control__wrapper .custom-label {
    color: #000;
}

/*     ::v-deep { */

.a-slider {
    width: 100%;
    padding-left: 20px;
    position: relative;
    top: 30px;
    margin-right: -8px;
}
  .a-slider {
    .v-slider-thumb {
      touch-action: none;
      color: rgb(255 251 251);
      border: 3px solid #424242;
      border-radius: 15px;
      &__label {
        background: #ffffff !important;
        color: #000000 !important;
        border: 2px solid #424242 !important;
        min-width: 62px !important;
        height: 30px !important;
      }
      &:nth-of-type(2) {
        .v-slider-thumb {
          &__label {
            top: -45px;
            &:after {
              content: "";
              position: absolute;
              top: 34px;
              width: 18px;
              height: 1px;
              background-color: #7B8794;
              /* 1px solid #7B8794 !important */
            }
            &:before {
              content: "";
              bottom: -12px !important;
              width: 14px;
              height: 18px;
              border-top: 0;
              background-color: #fff;
            }
          }
        }
      }
      &:nth-of-type(3) {
        .v-slider-thumb {
          &__label {
            top: -45px;
            &:after {
              content: "";
              position: absolute;
              top: 34px;
              width: 18px;
              height: 1px;
              background-color: #7B8794;
            }
            &:before {
              content: "";
              bottom: -12px !important;
              width: 14px;
              height: 18px;
              border-top: 0;
              background-color: #fff;
            }
          }
        }
      }
    }

    &.flip {
      .v-slider-thumb {
        &:nth-of-type(2) {
          .v-slider-thumb {
            &__label {
              left: -30px;
              &:before {
                right: -1px !important;
                border-right: 1px solid #7B8794;
              }
              &:after {
                left: 44px;
                rotate: -139deg;
              }
            }
          }
        }
        &:nth-of-type(3) {
          .v-slider-thumb {
            &__label {
              right: -90px;
              &:before {
                left: -1px !important;
                border-left: 1px solid #7B8794;
              }
              &:after {
                right: 44px;
                rotate: 139deg;
              }
            }
          }
        }
      }
    }

    &.unflip {
      .v-slider-thumb {
        &:nth-of-type(2) {
          .v-slider-thumb {
            &__label {
              left: 30px;
              &:before {
                left: -1px !important;
                border-left: 1px solid #7B8794;
              }
              &:after {
                right: 44px;
                rotate: 139deg;
              }
            }
          }
        }
        &:nth-of-type(3) {
          .v-slider-thumb {
            &__label {
              left: -30px;
              &:before {
                right: -1px !important;
                border-right: 1px solid #7B8794;
              }
              &:after {
                left: 44px;
                rotate: -139deg;
              }
            }
          }
        }
      }
    }
  }
/* } */
    
</style>