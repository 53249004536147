<template>
    <header class="header">
        <div class="header-content">
            <div v-if="userIsSuperAdmin" class="header-item d-flex align-items-center">
                <div class="site-logo-icon">
                    <router-link to="/clients">
                        <SiteLogoIcon/>
                    </router-link>
                </div>
                <h4 class="dashboard-title">Admin Console</h4>
            </div>
            <div v-else class="header-item d-flex align-items-center">
                <div class="site-logo-icon">
                    <router-link to="/messages">
                        <SiteLogoIcon/>
                    </router-link>
                </div>
                <h4 class="dashboard-title">Operator Console <span class="beta-mode">/ Beta</span></h4>
            </div>
            <div class="header-item menu-item" :class="{'show': isMenuOpen}">
                <!-- user?.AccountHolder || user?.SaasOperator -->
                
                    

               
                <nav class="navbar justify-content-center">
                   <!--  {{ this.$store.getters.isOnBehalf }} -->
                    <router-link 
                        v-if="user?.SaasOperator || this.$store.getters.isOnBehalf" 
                        to="/contacts" 
                        class="nav-link d-flex align-items-center" 
                        :class="{ 'active': $route.path === '/contacts' || $route.path.startsWith('/contacts/') }">
                        Contacts
                    </router-link>
                    <router-link 
                        v-if="user?.SaasOperator || this.$store.getters.isOnBehalf" 
                        to="/group"
                        class="nav-link d-flex align-items-center"
                        :class="{ 'active': $route.path === '/group' || $route.path.startsWith('/group/') }">
                        Groups
                    </router-link>
                    <router-link 
                        v-if="user?.SaasOperator || this.$store.getters.isOnBehalf" 
                        to="/messages"
                        class="nav-link d-flex align-items-center"
                        :class="{ 'active': $route.path === '/messages' || $route.path.startsWith('/messages/') }">
                        Messages
                    </router-link>
                    <router-link 
                        v-if="user?.SaasOperator || this.$store.getters.isOnBehalf" 
                        to="/activity"
                        class="nav-link d-flex align-items-center"
                        :class="{ 'active': $route.path === '/activity' || $route.path.startsWith('/activity/') }">
                        Activity
                    </router-link>
                    <router-link 
                        v-if="this.$root.userIsSuperAdmin()"
                        to="/clients" 
                        class="nav-link d-flex align-items-center" 
                        :class="{ 'active': $route.path === '/clients' || $route.path.startsWith('/clients/') }">
                        Clients
                    </router-link>
                    <router-link 
                        v-if="this.$root.userIsSuperAdmin()"
                        to="/archive"
                        class="nav-link d-flex align-items-center"
                        style="color: gray; pointer-events: none;"
                        :class="{ 'active': $route.path === '/archive' || $route.path.startsWith('/archive/') }">
                        Archive
                    </router-link>

                    <div v-if="this.$store.getters.isOnBehalf" class="custom-select-wrapper">
                         <!-- Custom Select Box -->
                         <div class="custom-select" @click="toggleDropdown">
                         <!-- Left Icon -->
                         <div class="left-icon">
                             <OperatorIcon2/>
                         </div>
                         <!-- Selected Option -->
                         <div class="selected-option">
                            {{selectedOption.FirstName}} {{selectedOption.LastName}}
                         </div>
                         <!-- Right Arrow -->
                         <div class="right-arrow" :class="{ rotate: isOpen }">
                             <OperatorIcon1/>
                         </div>
                         </div>
 
                         <!-- Dropdown Options -->
                         <ul v-if="isOpen" class="custom-dropdown">
                         <li 
                             v-for="(item, index) in items" 
                             :key="index" 
                             @click="selectOption(item)"
                             class="dropdown-item"
                         >
                             {{ item.FirstName }} {{ item.LastName }}
                         </li>
                         </ul>
                     </div>


                </nav>
                
                <div class="logout-links-con d-flex d-md-none align-items-center justify-content-center">
                    <h4 class="username"  @click="TogglePopup('buttonTrigger')">{{user?.FirstName}} {{ user?.LastName }}</h4>
                    <div class="logout-icon" @click="logout();">
                        <LogOutIcon/>
                    </div>
                </div>
            </div>
            <div class="header-item d-none d-md-flex align-items-center justify-content-end">
                <v-menu>
                <template v-slot:activator="{ props }">
                    <button 
                    v-bind="props"
                    class="btn-user"
                    >
                    <h4>{{user?.FirstName}} {{ user?.LastName }}</h4>
                    <NavmenuPulldownButton/>
                    </button>
                </template>
                <v-list>
                    <v-list-item @click="TogglePopup('buttonTrigger')">
                        <v-list-item-title><NavmenuProfileIcon/> Profile</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="navigateTo('/admin')" v-if="user?.AccountHolder || user?.SaasAdmin">
                        <v-list-item-title><NavmenuAdminIcon/> Admin</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="navigateTo('/account')" v-if="user?.AccountHolder">
                        <v-list-item-title><NavmenuAccountIcon/> Usage</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="logout();">
                        <v-list-item-title><NavmenuLogout/> Logout</v-list-item-title>
                    </v-list-item>
                </v-list>
                </v-menu>
            </div>
            
            <button class="btn btn-toggle d-block d-md-none" @click="toggleMenu">
                <MenuIcon/>
            </button>
        </div>
        <!-- <UpdateProfile 
            v-if="popupTriggers.buttonTrigger"  :title="'Operator'"
            :TogglePopup="() => TogglePopup('buttonTrigger')" :ProfileSaved="(data) =>ProfileSaved()" >
        </UpdateProfile> -->
        <!-- <AddUser v-if="popupTriggers.buttonTrigger" :editUser="user" :title="'Update Profile'"
            :TogglePopup="() => TogglePopup('buttonTrigger')" :source="'profile'" /> -->
        <UpdateProfile 
            v-if="popupTriggers.buttonTrigger"  :title="'Operator'"
            :TogglePopup="() => TogglePopup('buttonTrigger')" :ProfileSaved="(data) =>ProfileSaved()" >
        </UpdateProfile>
    </header>
</template>
<script>
import SiteLogoIcon from '@/components/icons/SiteLogoIcon.vue';
import LogOutIcon from '@/components/icons/LogOutIcon.vue';
import MenuIcon from '@/components/icons/MenuIcon.vue';
import OperatorIcon1 from '@/components/icons/OperatorIcon1.vue';
import OperatorIcon2 from '@/components/icons/OperatorIcon2.vue';
import UpdateProfile from '../UpdateProfile.vue'; 
import AddUser from '../AddUser.vue';
import { computed, ref } from 'vue';
import { VMenu, VList, VListItem, VListItemTitle} from 'vuetify';
import NavmenuAccountIcon from '@/components/icons/NavmenuAccountIcon.vue';    
import NavmenuAdminIcon from '@/components/icons/NavmenuAdminIcon.vue';
import NavmenuLogout from '@/components/icons/NavmenuLogout.vue';
import NavmenuProfileIcon from '@/components/icons/NavmenuProfileIcon.vue';
import NavmenuPulldownButton from '@/components/icons/NavmenuPulldownButton.vue';
import { useRouter } from 'vue-router';
import { globalState } from '@/globals.js';
const API_URL = process.env.VUE_APP_APIDOMAIN;
import AccountDeactivatedModal from '../modals/AccountDeactivatedModal.vue';
export default {
    name: 'Header',
    components: { UpdateProfile, SiteLogoIcon, LogOutIcon, MenuIcon, VMenu, VList, VListItem, VListItemTitle,NavmenuAccountIcon,NavmenuAdminIcon,NavmenuLogout,NavmenuProfileIcon,NavmenuPulldownButton,OperatorIcon1,OperatorIcon2 },
    setup () {
        const popupTriggers = ref({
            buttonTrigger: false
        });		
        const TogglePopup = (trigger) => {
            popupTriggers.value[trigger] = !popupTriggers.value[trigger]
        }
        const router = useRouter();
        return {
            UpdateProfile,
            popupTriggers,
            TogglePopup,
            router,
        }
    },
    mounted(){
        if(this.isFirstTime && !this.user.Phone) {
            this.TogglePopup("buttonTrigger");
        }
        document.addEventListener("click", this.handleClickOutside);
        if (this.isOnBehalf){
            this.getOperators(this.$store.getters.onBehalfUser.ClientID);
        }
    }, 
    beforeUnmount() {
         document.removeEventListener("click", this.handleClickOutside);
     },
    data() {
        return {
            isMenuOpen: false,
            user: computed(()=>this.$store.getters.user),
            isFirstTime: computed(()=>this.$store.getters.isFirstTime),
            isOpen: false, 
            selectedOption: {},
            items: [],
        };
    },
    computed: {
        /* userIsSuperAdmin() {
            //return globalState.isSuperAdmin;
            return (this.user?.SupportAdmin || 
                    this.user?.SuperAdmin) &&
                    !this.user?.AccountHolder &&
                    !this.user?.SaasAdmin &&
                    !this.user?.SaasOperator
        }, */
        isOnBehalf() {
            return this.$store.getters.isOnBehalf;
        } 
    },
    methods: {
        toggleMenu() {
            this.isMenuOpen = !this.isMenuOpen;
        },
        isActive(path) {
            return ref(this.$router.currentRoute).value.path === path
        },
        logout(){
            this.$root.logout();
        },
        ProfileSaved(){
            this.popupTriggers.buttonTrigger = false;
            window.location.reload();
        },
        navigateTo(path) {
            this.router.push(path);
        },
        getOperators(ClientID){
          let url = `${API_URL}/getclientoperators?ClientID=${ClientID}`;
          fetch(url, { 
              method: 'GET', 
              headers: {
                "content-type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem("tknds")
              } 
            })                      
              .then(response => response.json())
              .then(async (result) => {
                //Auth Token Failed   
                if(result.AuthSuccess == 0){
                      this.$root.logout();                      							           
                }else{
                  this.items = result;
                  if ( this.$store.getters.onBehalfUser.UserID ){
                    const selectedItem = this.items.filter(a=> a.UserID == this.$store.getters.onBehalfUser.UserID);
                    this.selectOption(selectedItem[0]);
                  } else {
                    this.selectOption(this.items[0]);
                  }
                            
                }                 
          })
        },
        toggleDropdown() {
             this.isOpen = !this.isOpen;
         },
         selectOption(item) {
             this.selectedOption = item;
             this.$store.commit("setOnBehalfUser", {'ClientID':this.$store.getters.onBehalfUser.ClientID, 'UserID': this.selectedOption.UserID});
             this.isOpen = false; 
         },
         handleClickOutside(event) {
             const selectWrapper = this.$el.querySelector('.custom-select-wrapper');
             if (selectWrapper && !selectWrapper.contains(event.target)) {
                 this.isOpen = false;
             }
         },
    },
    watch :{
        isOnBehalf: {
        handler: function (val, oldVal) {
            if(val){
                this.getOperators(this.$store.getters.onBehalfUser.ClientID);
            }
        },
        deep: true,
        },
    }
}
</script>
<style lang="scss" scoped>
 .header{
     width: 100%;
     padding: 15px 25px 15px 15px;
     background: #F2F2F2;
     position: relative;
     min-height: 80px;
     .header-content{
        display: grid;
        grid-template-columns: 7fr 10fr 7fr;
        align-items: center;
    }
    .site-logo-icon{
        margin-right: 25px;
        width: 50px;
        height: 50px;
    }
    .header-item{
        min-width: 215px;
        h4{
            color: #000;
            font-family: "Roboto";
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 80%;
            & .beta-mode {
                font-size: 15px;
                line-height: 16px;
                font-weight: 400;
                color: #7B8794;
            }
            &.username{
                padding: 7px 6px;
                font-weight: 400;
                cursor: pointer;
                &:hover{
                    background: #fff;
                    border-radius: 8px;
                }
            }
        }
    }
    .menu-item{
        @media screen and (max-width: 960px) {
            display: none;
            position: absolute;
            z-index: 9;
            width: 100%;
            top: 100%;
            left: 0;
            background: #ffffff;
            padding: 30px;
            box-shadow: 0 10px 10px 0 rgba(0,0,0,0.1);
            &.show{
                display: block;
            }
            .navbar{
                flex-direction: column;
            }
            .logout-links-con{
                margin-top: 30px;
            }

        }
    }
    .logout-icon{
        margin-left: 8px;
    }
    .btn-toggle{
        outline: none;
        opacity: 0.7;
        &:active, &:focus{
            outline: none;
            box-shadow: none;
        }
    }
    .navbar{
        padding: 0;
        display: flex;
        align-items: center;
        gap: 36px;
        .nav-link{
            padding: 8px 0 4px;
            display: inline-flex;
            align-items: flex-start;
            color: #000;
            text-align: center;
            font-family: "Roboto";
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 1;
            border-bottom: 4px solid transparent;
            &.active, &:hover{
                border-bottom-color: #000;
            }
        }
        @media screen and (max-width: 768px) {
            gap: 30px;
        }
    }
    .btn-user{
        background: transparent;
        border-radius: 5px;
        padding: 3px 3px 3px 7px;
        cursor: pointer;
        box-shadow: none;
        text-transform: none;
        display: flex;
        align-items: center;
        // min-width: 162px;
        gap: 1px;
        transition: all 0.2s ease;
        justify-content: flex-end;
        & h4 {
            font-size: 20px;
            line-height: 16px;
            font-weight: 400;
            color: #000;
        }
        &:hover , &:focus{
            background: #fff;
        }
    }
 }
 .v-list-item {
    padding: 12px 20px;
 }
 .v-list-item-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
    display: flex;
    align-items: center;
    gap: 12px;
    color:#828282;
 }
 
 .v-list.v-theme--light.v-list--density-default.v-list--one-line {
    max-width: 162px !important;
    min-width: 162px !important;
    margin-left: auto;
    width: 162px !important;
    margin-top: 4px;
 }

 .custom-select-wrapper {
   position: relative;
   width: 178px;
   margin-right: -206px;
 }
 
 /* Custom Select Box */
 .custom-select {
   display: flex;
   align-items: center;
   border-radius: 15px;
   padding: 0 8px;
   color: white;
   gap: 8px;
   cursor: pointer;
   background-color: #7B8794;
   position: relative;
   transition: border 0.3s ease;
   height: 30px;
 }
 
 
 .left-icon {
   width: 24px;
   height: 24px;
   flex-shrink: 0;
   position: relative;
     top: -1.5px;
 }
 
 .selected-option {
     flex-grow: 1;
     color: white;
     font-size: 14px;
     font-weight: 500;
     line-height: 16.41px;
     text-align: left;
     white-space: nowrap;
     overflow: hidden;
     text-overflow: ellipsis;
 }
 
 .right-arrow {
   width: 24px;
   height: 24px;
   flex-shrink: 0;
   position: relative;
     top: -1.5px;
 }
 
 .right-arrow svg {
   transition: transform 0.3s ease;
 }
 
 .right-arrow.rotate svg {
   transform: rotate(180deg);
 }
 
  
 
 /* Dropdown Styles */
 .custom-dropdown {
   position: absolute;
   top: 100%;
   left: 0;
   right: 0;
   background: #7B8794;
   border-radius: 10px;
   list-style: none;
   margin: 3px 0 0;
   padding: 0;
   z-index: 10;
   box-shadow: 0px 4px 15px 0px #00000040;
   padding: 10px 0;
 }
 
 .dropdown-item {
   padding: 7px 15px;
   cursor: pointer;
   transition: background-color 0.3s ease;
   color: white;
     font-size: 14px;
     font-weight: 500;
     line-height: 16px;
 }
 
 .dropdown-item:hover {
   background-color: rgba(0,0,0,0.1);
   color: white;
 }

</style>