const Roles = {
    AccountHolder: "AccountHolder",
    SaasOperator: "SaasOperator",
    SaasAdmin: "SaasAdmin",
}

const InvitationStatusType = {
    ACTIVE: 'ACTIVE',
    CANCELLED: 'CANCELLED',
    EXPIRED: 'EXPIRED'
}

module.exports = {Roles, InvitationStatusType};