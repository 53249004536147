<template>
    <div>
        <v-menu :disabled="disabled" v-model="menu" :close-on-content-click="false" offset-y>
            <template v-slot:activator="{ props }">
                <div v-bind="props" style="text-align: center">
                    <IconDetails v-if="source=='accountholder'"/>
                    <SettingDots v-else/>
                </div>
            </template>
            <v-card class="user-details-modal">
                <button type="button" class="close-modal" @click="closeMenu">
                    <IconClose />
                </button>
                <div class="user-details-top">
                    <div class="user-details">
                        <div class="title">{{ user?.FirstName + " " + user?.LastName }} - Details</div>
                        <ul v-if="user.SaasOperator" :class="{ 'is-selectable': isDataTransferEnabled, 'is-selected': isDataTransferEnabled }"
                            class="user-selector">
                            <li v-if="user.SaasOperator">
                                <IconContacts />Contacts:<span>{{ data?.contact ?? "0" }}</span>
                            </li>
                            <li v-if="user.SaasOperator">
                                <IconGroup />Groups:<span>{{ data?.group ?? "0" }}</span>
                            </li>
                            <li v-if="user.SaasOperator">
                                <IconMessages />Messages:<span>{{ data?.message ?? "0" }}</span>
                            </li>
                        </ul>
                        <!-- <div @click="selectSender('operators')"  :class="{'is-selectable': isDataTransferEnabled, 'is-selected': isDataTransferEnabled && selectedItem === 'operators'}" class="operator-selector">
                                <IconOperator />Operators: <span>{{ data.operator ?? "0" }}</span>
                            </div> -->
                        <!-- <div v-if="!isDataTransferEnabled && user.SaasAdmin" class="flex gap-2 items-center" style="padding-left: 10px;">
                            <IconOperator />Operators: <span>{{ data?.operator ?? "0" }}</span>
                        </div> -->
                    </div>
                    <div v-if="isDataTransferEnabled" class="receiver-details">
                        <div class="title">Select an Operator...</div>
                        <ul class="receiver-list">
                            <li v-for="(operator, index) in operators" :key="index" @click="selectReceiver(operator)"
                                :class="{ 'active': selectedReceiver === operator }">
                                {{ operator?.FirstName + " " + operator?.LastName }}
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="user-details-bottom mt-2" v-if="user?.SaasOperator">
                    <button @click="toggleDataTransfer()" type="button" class="enable-button">
                        <div class="icon">
                            <IconCheckboxChecked v-if="isDataTransferEnabled" />
                            <IconCheckboxUnchecked v-else />
                        </div>Enable Data Transfer
                    </button>
                    <div v-if="isDataTransferEnabled && selectedItem !== null" class="action-buttons">
                        <button @click="toggleDataTransfer();" class="cancel-btn" type="button">Cancel</button>
                        <button @click="confirmDataTransferModal=true" :class="{ 'disabled-btn': !selectedReceiver }"
                            class="transfer-data-btn" type="button">Transfer Data
                            <ArrowInsideButton />
                        </button>
                    </div>
                </div>
            </v-card>
        </v-menu>
    </div>

    <Confirmation 
    v-model="confirmDataTransferModal" 
    :data="null" 
    @closed="confirmDataTransferModal = false" 
    :onconfirm="() => transferData()"
    :body="`
        Are you sure you want to transfer this Operator Data? <br><br>
        This Data will be managed by another Operator.<br><br>
        This Data transfer cannot be undone.<br><br>
    `" 
  />
</template>

<script setup>
import { ref, defineProps, defineEmits, watch, computed, onMounted } from 'vue';
import SettingDots from '@/components/icons/message_settings/SettingDots.vue';
import IconContacts from '@/components/icons/IconContacts.vue';
import IconGroup from '@/components/icons/IconGroup.vue';
import IconMessages from '@/components/icons/IconMessages.vue';
import IconOperator from '@/components/icons/IconOperator.vue';
import IconCheckboxUnchecked from '@/components/icons/IconCheckboxUnchecked.vue';
import IconCheckboxChecked from '@/components/icons/IconCheckboxChecked.vue';
import IconClose from '@/components/icons/IconClose.vue';
import ArrowInsideButton from "@/components/icons/ArrowInsideButton.vue";
import userService from '@/services/userService';
import { useStore } from 'vuex';
import Confirmation from '../modals/Confirmation.vue';
import IconDetails from '../icons/IconDetails.vue';

let props = defineProps(['content', 'item','source']);
let emits = defineEmits(['item-selected', 'success','error']);
let menu = ref(false);
let disabled = ref(props.disabled);
let user = computed(()=>props.item);
let store = useStore();
let currentUser = computed(() => store.getters.user);
let data = ref({});
let confirmDataTransferModal = ref(false);


const closeMenu = () => {
    menu.value = false;
    confirmDataTransferModal.value=false;
};

let operators = ref([]);

let isDataTransferEnabled = ref(false);
const selectedReceiver = ref(null);

function selectReceiver(receiver) {
    selectedReceiver.value = receiver;
}

async function getUserProfileData() {
    data.value = await userService.getUserProfileData(user.value?.UserID);
}

function toggleDataTransfer() {
    isDataTransferEnabled.value = !isDataTransferEnabled.value;
    if(isDataTransferEnabled.value){
        getOperators();
    }
}

async function getOperators() {
    let ops = await userService.getOperators();
    operators.value = ops.filter(op=>op.UserID!=user?.value?.UserID)
}

async function transferData() {
    try {
        let res = await userService.transferData(user.value?.UserID, selectedReceiver.value?.UserID);
        toggleDataTransfer();
        emits("success","Data transferred successfully!")
    } catch (error) {
        emits('error',error?.message)
    }
    closeMenu();

}

watch(menu, (val) => {
    if (val) {
        getUserProfileData();
    }
})
</script>
<style lang="scss" scoped>
.user-details-modal {
    width: 600px;
    height: 350px !important;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & .close-modal {
        position: absolute;
        right: 10px;
        top: 10px;
    }

    & .user-details-top {
        display: grid;
        grid-template-columns: 250px 250px;
        gap: 60px;

        & .user-details {
            display: flex;
            flex-direction: column;

            & .title {
                font-family: Roboto;
                font-size: 16px;
                font-weight: 500;
                line-height: 16px;
                text-align: left;
                margin-bottom: 18px;
                color: #333333;
            }

            & .user-selector {
                display: flex;
                flex-direction: column;
                padding: 10px;
                gap: 18px;
                font-size: 16px;
                line-height: 1;
                font-weight: 500;
                border-radius: 5px;
                margin-bottom: 15px;
                height: 150px;
                border: 1.5px solid transparent;

                &.is-selectable {
                    border: 1.5px solid #D9D9D9;
                    cursor: pointer;
                }

                &.is-selected {
                    background-color: #F2F2F2;
                }

                & li {
                    display: flex;
                    align-items: center;
                    gap: 6px;
                    color: #828282;
                    font-size: 16px;
                    line-height: 1;
                    font-weight: 500;

                    & span {
                        color: #344D7A;
                    }
                }
            }

            & .operator-selector {
                display: flex;
                align-items: center;
                gap: 6px;
                padding: 10px;
                font-size: 16px;
                line-height: 1;
                font-weight: 500;
                border-radius: 5px;
                color: #828282;
                height: 50px;
                border: 1.5px solid transparent;

                &.is-selectable {
                    border: 1.5px solid #D9D9D9;
                    cursor: pointer;
                }

                &.is-selected {
                    background-color: #F2F2F2;
                }

                & span {
                    color: #344D7A;
                }
            }
        }

        & .receiver-details {
            display: flex;
            flex-direction: column;

            & .title {
                font-family: Roboto;
                font-size: 16px;
                font-weight: 400;
                line-height: 16px;
                text-align: left;
                margin-bottom: 18px;
                color: #333333;
            }

            & .receiver-list {
                border: 1.5px solid #D9D9D9;
                border-radius: 5px;
                display: flex;
                flex-direction: column;
                padding-left: 0;
                height: 150px;
                overflow: auto;

                & li {
                    display: flex;
                    align-items: center;
                    padding: 3px 15px;
                    font-family: Roboto;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 28px;
                    text-align: center;
                    color: #333333;
                    transition: 0.2s all ease;
                    cursor: pointer;

                    &.active {
                        background-color: #EAF2FC;
                    }

                    &:hover {
                        background-color: #EAF2FC;
                    }
                }
            }
        }
    }

    & .user-details-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 12px;
        height: 40px;


        & .enable-button {
            display: flex;
            align-items: center;
            gap: 10px;
            font-family: Roboto;
            font-size: 16px;
            font-weight: 400;
            line-height: 16px;
            text-align: left;
            color: #000;

            & .icon {
                width: 24px;
                height: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        & .action-buttons {
            display: flex;
            align-items: center;
            gap: 58px;

            & .cancel-btn {
                font-family: Roboto;
                font-size: 14px;
                font-weight: 500;
                line-height: 16.41px;
                text-align: right;
                color: #2F80ED;
                text-transform: uppercase
            }

            & .transfer-data-btn {
                width: 139px;
                height: 40px;
                display: flex;
                align-items: center;
                gap: 5px;
                padding: 8px 6px;
                justify-content: flex-end;
                background-color: #4066CF;
                border: 1px solid #4066CF;
                font-family: Roboto;
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                letter-spacing: 0.10000000149011612px;
                text-align: left;
                color: white;
                border-radius: 5px;
                transition: 0.2s all ease;

                &.disabled-btn {
                    border-color: #BDBDBD;
                    color: #BDBDBD;
                    pointer-events: none;
                    background-color: white;
                }

                &:hover {
                    border-color: #6B87D4;
                    background-color: #6B87D4;
                }
            }
        }

    }

}
</style>