<template>
  <v-dialog v-model="dialog" class="dialog" max-width="960px">
    <div class="modal-container">
      <span class="vimeo-modal-close" @click="closeVideo">&times;</span>
      <iframe
        v-if="videoUrl"
        :src="videoUrl"
        width="960"
        height="540"
        frameborder="0"
        allow="autoplay; fullscreen"
        allowfullscreen
        class="vimeo-iframe"
      ></iframe>
    </div>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      videoUrl: "",
    };
  },
  methods: {
    openVideo(url) {
      this.videoUrl = url;
      this.dialog = true;
    },
    closeVideo() {
      this.videoUrl = "";
      this.dialog = false;
    },
  },
};
</script>

<style scoped>
.vimeo-iframe {
  border-radius: 8px;
  background: black;
  max-width: 960px;
  max-height: 100%;
  height: 540px;
  margin: 0 auto;
  box-shadow: 0 11px 15px -7px var(--v-shadow-key-umbra-opacity,#0003),0 24px 38px 3px var(--v-shadow-key-penumbra-opacity,#00000024),0 9px 46px 8px var(--v-shadow-key-ambient-opacity,#0000001f);
}

.modal-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.vimeo-modal-close {
  position: absolute;
  top: -13px;
  right: -13px;
  background: white;
  color: black;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  border-radius: 50%;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
</style>