<template>
  <div class="signin-form-con" id="authpagecontainer">
    <div class="text-center site-logo">
      <SiteLogo />
    </div>
    <div class="site-tagline">
      <h5 class="font-acumin text-center text-primary fw-semibold line-h-120">
        Geofence Messaging <br> in minutes
      </h5>
    </div>
    <div class="account-forms-wrapper">
      <div id="firebaseui-auth-container"></div>
    </div>
  </div>
</template>

<script>
import SiteLogo from '@/components/icons/SiteLogo.vue';
import firebase from 'firebase/compat/app';
import 'firebase/auth'; // Import firebase/auth to make sure the auth module is included

import * as firebaseui from 'firebaseui';
import api from '@/services/api';
import userService from '@/services/userService';
import { watch } from 'vue';
var ui;

export default {
  name: 'auth',
  components: { SiteLogo },
  data() {
    return { fullName: "" }
  },

  mounted() {
    // Check if user is logged in and redirect to home page
    if (this.$root.loggedInUser) {
      document.location.href = "/";
    }

    // Ensure Firebase is initialized
    if (!firebase.apps.length) {
      firebase.initializeApp();
    }


    var uiConfig = {
      signInSuccessUrl: '/messages',
      signInFlow: 'popup',
      signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
      ],
      callbacks: {
        signInSuccessWithAuthResult: async (authResult) => {
          return true;
        }
      }
    };

    // Ensure firebase.auth() is available before using it
    if (!firebase.auth()) {
      console.error('Firebase Auth is not available.');
      return;
    }

    ui = firebaseui.auth.AuthUI.getInstance();
    if (!ui) {
      ui = new firebaseui.auth.AuthUI(firebase.auth());
    }

    ui.start('#firebaseui-auth-container', uiConfig);


    setTimeout(function () {
      if (document.getElementById('authpagecontainer')) {
        document.getElementById('authpagecontainer').style.opacity = 1;
      }
    }, 2000);

    const observer = new MutationObserver(async (mutationsList, observer) => {
      const emailInput = document.querySelector('.firebaseui-id-email');
      const nameInput = document.querySelector('.firebaseui-id-name');
      if (emailInput) {
        try{
          let data = await userService.getInvitation(emailInput.value);
          if(data!=''){
            data = data?.at(0);
            this.fullName = `${data.FirstName} ${data.LastName}`;
          }
        }catch(e) {
          // this.fullName = "Not registered";
        }

        if (nameInput && this.fullName) {
          nameInput.value = this.fullName;
          nameInput.blur();
          nameInput.parentElement.classList.add('is-dirty');
          nameInput.parentElement.classList.add('disabled');
          nameInput.parentElement.classList.add('disabled-dark');
        }
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });
  },
};
</script>

<style lang="scss">
.signin-form-con {
  border-radius: 20px;
  background: rgba(255, 255, 255, 1);
  padding: 60px 50px 48px;
  width: 400px;
  height: 632px;

  .disabled-dark {
    opacity: 1!important;
  }


  @media screen and (max-width: 992px) {
    width: 100%;
    padding: 30px;
    min-height: 600px;
    height: auto;
  }
}

.site-logo {
  margin-bottom: 45px;
}

.site-tagline {
  margin-bottom: 70px;

  h5 {
    font-size: 20px;
    font-style: normal;
    line-height: 24px;
  }
}


.firebaseui-idp-button {
  background: #fff !important;
  display: flex !important;
  align-items: center;
  gap: 20px;

  &:hover {
    background: #dadada !important;
  }
}

.firebaseui-idp-text {
  color: rgba(0, 0, 0, 0.54) !important;
  padding-left: 0 !important;
}

.mdl-button--raised {
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.17), 0px 0px 1px 0px rgba(0, 0, 0, 0.08) !important
}

.footercopyright .md-layout-item,
.footermenu .md-layout-item {
  font-weight: normal !important
}

.footermenu {
  margin-left: 92px
}

.firebaseui-card-content {
  padding: 0 !important
}

.firebaseui-idp-button,
.firebaseui-tenant-button {
  max-width: 100% !important
}

.firebaseui-list-item:first-child {
  margin-bottom: 45px !important
}

.firebaseui-list-item:nth-of-type(2) {
  .firebaseui-idp-icon-wrapper {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url("../assets//images/icons/Email-icon.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    img {
      display: none !important;
    }
  }
}

.mdl-shadow--2dp {
  box-shadow: none !important
}

.firebaseui-card-header {
  padding: 0 !important
}

.firebaseui-container {
  background: none !important
}

.mdl-button--raised.mdl-button--colored {
  border-radius: 3px;
  background: #4066CF !important;
  color: #fff !important;
  padding: 8.5px 16.4px;
  font-family: "Roboto";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  &:hover {
    background: #6B87D4 !important;
    color: #fff !important;
  }
}

.mdl-shadow--2dp {
  box-shadow: none !important
}

.mdl-textfield {
  padding: 20px 0 0 !important;
}

.mdl-textfield__label:after {
  top: 98%;
}

.firebaseui-error-wrapper {
  position: relative;
  top: 19px;
}
</style>