<template>
 
<!-- Generator: Adobe Illustrator 26.4.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve">
 
<g>
	<g>
		<path fill="none" stroke="#F2F2F2" stroke-width="1.9636" stroke-linecap="round" stroke-linejoin="round" d="M3,12c0,0,3.3-6.5,9-6.5s9,6.5,9,6.5s-3.3,6.5-9,6.5S3,12,3,12z"/>
		<path fill="none" stroke="#F2F2F2" stroke-width="1.9636" stroke-linecap="round" stroke-linejoin="round" d="M12,14.9c1.6,0,2.9-1.3,2.9-2.9S13.6,9.1,12,9.1c-1.6,0-2.9,1.3-2.9,2.9S10.4,14.9,12,14.9z"/>
	</g>
</g>
</svg>


</template>