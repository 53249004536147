<template>

    <v-dialog v-model="dialog" persistent class="account-transfer-modal">
        <v-card>
            <v-card-title>
                <span class="dialog-title">Account</span>
            </v-card-title>
            <v-card-text>
                <v-radio-group v-model="selectedOption" hide-details="true">
                    <ul class="transfer-options-list">
                        <li class="option" :class="{ 'selected-option': selectedOption == 'transfer' }">
                            <v-radio value="transfer">
                                <template #label>
                                    <h5 class="d-flex align-center">
                                        <div>Transfer ownership to user</div>
                                        <v-tooltip location="top" content-class="custom-tooltip">
                                            <template #activator="{ props }">
                                                <IconQuestionMarkSm v-bind="props" class="ml-2" />
                                            </template>
                                            <div>
                                                <div class="tooltip-title">Title</div>
                                                <p class="tooltip-message">
                                                    Content
                                                </p>
                                            </div>
                                        </v-tooltip>
                                    </h5>
                                </template>
                            </v-radio>

                            <div class="receiver-details-container">
                                <div class="receiver-details">
                                    <ul class="receiver-list">
                                        <li v-for="(operator, index) in operators" :key="index"
                                            @click="selectUserToTransfer(operator)"
                                            :class="{ 'active': selectedOperator === operator }">
                                            {{ operator?.FirstName + " " + operator?.LastName }}
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </li>
                        <li class="option" :class="{ 'selected-option': selectedOption == 'invite' }">
                            <v-radio value="invite">
                                <template #label>
                                    <h5 class="title">
                                        <div>
                                            Transfer ownership to a person who is not yet signed up.
                                        </div>
                                        <div>
                                            <IconQuestionMarkSm />
                                            <v-tooltip activator="parent" location="top" content-class="custom-tooltip">
                                                <div>
                                                    <div class="tooltip-title">Title</div>
                                                    <p class="tooltip-message">
                                                        Content
                                                    </p>
                                                </div>
                                            </v-tooltip>
                                        </div>
                                    </h5>
                                </template>
                            </v-radio>
                            <!-- <div class="receiver-details-container">
                                <v-text-field label="Email*" :rules="selectedOption === 'invite' ? emailRules : []"
                                    v-model="inviteeEmail" v-on:update:focused="selectedOption = 'invite'"
                                    class="emailTextField"
                                    :bg-color="selectedOption == 'invite' ? 'transparent' : 'white'" clearable
                                    :clear-icon="'mdi-close'"></v-text-field>
                            </div> -->
                        </li>
                    </ul>
                </v-radio-group>
            </v-card-text>
            <v-card-actions style="gap: 50px;">
                <v-btn :ripple="false" class="close-btn text-btn btn" variant="text" @click="onCloseWithoutSave">
                    Close
                </v-btn>
                <v-btn :ripple="false" class="transfer-data-btn" variant="text" :disabled="!enableSaveButton"
                    @click="onTransferAccountClick">
                    <div>
                        <p v-if="selectedOption=='transfer'">Transfer Data</p>
                        <p v-if="selectedOption=='invite'">Invite New User</p>
                        <ArrowInsideButton />
                    </div>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <ConfirmationCustom v-model="isConfirmModal" @closed="isConfirmModal = false" :onconfirm="confirmTransfer" title="Confirmation">
        <div v-if="selectedOption == 'transfer'">
            <p>You are transferring the ownership of this account to a user who is already in the account.</p> <br>
            <p>This transfers full permission to add and remove users.</p> <br>
            <p>This also transfers the responsibility for the usage of Sig2, and the ability to increase or decrease it.
            </p>
            <br>
            <p>The new <i>Account Holder</i> is responsible for billing.</p> <br>
        </div>
    </ConfirmationCustom>

</template>

<script setup>
import { ref, defineEmits, defineProps, computed, watch, onMounted } from 'vue';
import userService from '@/services/userService';
import IconQuestionMarkSm from '../icons/IconQuestionMarkSm.vue';
import ConfirmationCustom from './ConfirmationCustom.vue';
import ArrowInsideButton from '../icons/ArrowInsideButton.vue';
let props = defineProps({
    show: Boolean,
})
const emits = defineEmits(['closed', 'setting-saved', 'open-invite-modal']);
let emailRules = [
    v => !!v || 'Email field is required',
    v => /.+@.+\..+/.test(v) || 'Email must be valid'
];
let selectedOption = ref("transfer");
const isConfirmModal = ref(false);
const selectedOperator = ref(null);
let operators = ref([]);
let inviteeEmail = ref("");

let enableSaveButton = computed(() => {
    if (
        (selectedOption.value == 'invite') ||
        (selectedOption.value == 'transfer' && selectedOperator.value)
    ) {
        return true;
    }
    return false;
});


let dialog = computed({
    get() {
        return props.show;
    },
    set(value) { emits('closed', value); }
})

let onCloseWithoutSave = function () {
    dialog.value = false;
}

let onTransferAccountClick = async () => {
    if(selectedOption.value == 'invite') {
        emits('open-invite-modal')
        dialog.value = false;
        return;
    }
    isConfirmModal.value = true;
    dialog.value = false;
}

async function getOperators() {
    let ops = await userService.getAllUsers();
    operators.value = ops.filter(op=>op.UserID);
    console.log(operators.value);
}

function selectUserToTransfer(operator) {
    selectedOperator.value = operator;
    selectedOption.value = 'transfer';
}

async function confirmTransfer(){
    if(selectedOption.value=="transfer") {
        let res = await userService.transferAccount(selectedOperator.value?.UserID);
        location.reload();
    } else {
        emits('open-invite-modal')
        // alert('Under Construction');
    }
}

onMounted(() => {
    getOperators();
    watch(selectedOption, (newVal, oldVal) => {
        if (newVal == 'transfer') {
            inviteeEmail.value = "";
        } else {
            selectedOperator.value = null;
        }
    });
})

</script>


<style lang="scss">
.account-transfer-modal {
    & .v-overlay__content {
        width: 900px;

        & .v-card {
            height: 458px;

            & .v-card-text {
                padding: 0 30px 0 30px;
                display: flex;
                font-size: 16px;
                font-weight: 500;
                line-height: 16px;
                flex-direction: column;
                justify-content: center;
            }
        }
    }

    & .v-card-title {
        line-height: 1 !important;
        padding: 22px 30px 10px !important;
        font-size: 16px;
    }

    & .v-card-actions {
        margin-top: 0px !important;
        margin-right: 30px;
        margin-bottom: 34px;

        & .transfer-data-btn {
            width: 174px;
            height: 40px;
            display: flex;
            align-items: center;
            gap: 10px;
            padding: 10px;
            justify-content: center;
            background-color: #4066CF;
            border: 1px solid #4066CF;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0.10000000149011612px;
            text-align: left;
            color: white;
            border-radius: 5px;
            transition: 0.2s all ease;
            text-transform: none !important;

            &.disabled-btn {
                border-color: #BDBDBD;
                color: #BDBDBD;
                pointer-events: none;
                background-color: white;
            }

            &:hover {
                border-color: #6B87D4;
                background-color: #6B87D4;
            }

            & div {
                display: flex;
                gap: 15px;
                align-items: center;
                margin-left: 20px;
                margin-right: 10px;
            }
        }
    }
}

.transfer-options-list {
    display: flex;
    flex-direction: column;
    gap: 19px;
    padding: 0;
    margin: 0;
    margin-top: 38px;
    margin-bottom: 23px;

    & .option {
        border-radius: 10px;
        border: 1.5px solid #fff;
    }

    & .option:nth-child(1) {
        padding: 17px 53px 17px 36px;
    }

    & .option:nth-child(2) {
        padding: 27px 53px 27px 36px;
    }

    & .selected-option {
        background-color: #F2F2F2;
        border: 1.5px solid #D9D9D9;
    }

    & li {
        display: flex;
        align-items: center;
        gap: 19px;

        & h5 {
            font-size: 16px;
            line-height: 1;
            font-weight: 500;
            display: flex;
            align-items: center;
            gap: 10;
        }
    }

    & .receiver-details-container {
        width: 400px;
        margin-left: auto;

        & .receiver-details {
            display: flex;
            flex-direction: column;

            & .title {
                font-family: Roboto;
                font-size: 16px;
                font-weight: 400;
                line-height: 16px;
                text-align: left;
                margin-bottom: 18px;
                color: #333333;
            }

            & .receiver-list {
                border: 1.5px solid #D9D9D9;
                background-color: white;
                border-radius: 5px;
                display: flex;
                flex-direction: column;
                padding-left: 0;
                height: 120px;
                overflow: auto;

                & li {
                    display: flex;
                    align-items: center;
                    padding: 3px 15px;
                    font-family: Roboto;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 28px;
                    text-align: center;
                    color: #333333;
                    transition: 0.2s all ease;
                    cursor: pointer;

                    &.active {
                        background-color: #EAF2FC;
                    }

                    &:hover {
                        background-color: #EAF2FC;
                    }
                }
            }
        }
    }

}
</style>