<template>
  <v-row>
 
    <div class="w-100" v-if="showSuccess">
      <SuccessMessagePanel>
        <p>
          <b>Confirm:{{ removedContactCount }}</b> Contacts Added.
        </p>
      </SuccessMessagePanel>
    </div> 
    <!-- {{selectedMember}} -->
    <S2gTable
      :indeterminate="indeterminate"
      :items="contacts"
      :headers="[{ key: null, title: null }]"
      :filterItems="[
        { label: 'All', value: 'all' },
        { label: 'None', value: 'none' },
      ]"
      @column-filter="handleFilter"
      @toggle-row-select="selectMembers"
      :selectedItemCount="selectedContacts"
      @search="searchMember"
      :isReload="false"
      :isSelectGlobalCheckBox="isSelectAll"
      :totalItems="`${contactPage.pager.totalItems}`"
      :totalCountText="'Contacts'"
      :totalCountText1="'Contact'"
      :showCount="true"
      @select-all="selectAllContacts"
      :page="(contactPage.currentPage - 1) * contactPage.itemsPerPage"
    >
      <template v-slot:table_body="{ item }">
        <td>
          <div class="d-flex justify-space-between">
            <span
              >{{ item.item.FirstName }}
              {{ item.item.LastName }}
            </span>
          </div>
        </td>
      </template>
    </S2gTable>
    <v-row class="mt-0">
      <v-col cols="12" sm="6" md="6">
        <div class="pagination">
          <Paginaiton
            v-if="contactPage.pager.totalPages > 1"
            :currentPage="contactPage.currentPage"
            :totalItems="contactPage.pager.totalItems"
            :itemsPerPage="contactPage.itemsPerPage"
            :totalPages="contactPage.pager.totalPages"
            @page-changed="changePage"
          />
        </div>
      </v-col>
      <v-col class="d-flex justify-end" cols="12" sm="6" md="6">
        <button type="button"
          class="btn-iconed"
          :class="{
            'btn-iconed-disabled': !activateGroupBtn || this.$root.userIsSuperAdmin(),
          }"
          @click="addContactsToGroupFn"
        >
          Add to group
          <ArrowInsideButton />
        </button>
      </v-col>
    </v-row>
  </v-row>
</template>

<script>
const API_URL = process.env.VUE_APP_APIDOMAIN + "/";
import S2gTable from "@/components/common/S2gTable.vue";
import SuccessMessagePanel from "@/components/common/SuccessMessagePanel.vue";
import Paginaiton from "../../components/common/Paginaiton.vue";
import ArrowInsideButton from "@/components/icons/ArrowInsideButton.vue";

import {
  manageUnSelectedItems,
  manageSelectedItems,
  manageSingleChecked,
} from "@/helpers/s2gtable-helper";
export default {
  components: {
    S2gTable,
    Paginaiton,
    SuccessMessagePanel,
    ArrowInsideButton
  },
  props: {
    group: {
      default: () => {
        return {};
      },
      type: Object,
    },
    selectedMember: {
      default: () => {
        return {};
      },
      type: Object,
    },
  },

  data: () => ({
    dialog: false,
    loading: false,
    isSelectAll: false,
    showSuccess: false,
    selectedMembers: 0,
    indeterminate: false,
    activateGroupBtn: false,
    contacts: [],
    tab: null,
    removedContactCount: 0,
    contactPage: {
      pager: { totalPages: 0, totalItems: 0 },
      currentPage: 1,
      itemsPerPage: 10,
    },
    token: localStorage.getItem("tknds") || "",
    selectedContacts: 0,
    showConfirm: false,
    searchMemberText: "",
    selectedContactIds: [],
    uncheckedMembers: [],
    checkedMember: [],
    activateAddGroup: false,
  }),
  methods: {
    handleFilter(e) {
      const event = e.e;
      if (event.value == "all") {
        this.selectAllContacts({ target: { checked: true } });
      } else {
        this.selectAllContacts({ target: { checked: false } });
      }
    },
    getContacts() {
      let url =
        API_URL +
        "teams_without_group_member/?page=" +
        this.contactPage.currentPage +
        "&perpage=" +
        this.contactPage.itemsPerPage +
        "&sort=none" +
        "&search=" +
        this.searchMemberText;

      url += "&Role=" + this.$root.userdetail.user[0].Role;
      if (this.$store.getters.isOnBehalf){
          url+= "&ClientID="+this.$store.getters.onBehalfUser.ClientID;
          url+= "&CreatedBy="+ this.$store.getters.onBehalfUser.UserID;
      } else {
          url += "&ClientID=" + this.$root.userdetail.user[0].ClientID;
          url += "&CreatedBy=" + this.$root.userdetail.user[0].UserID;
      }
      url += "&group_id=" + this.group.Id;
      fetch(url, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + this.token,
        },
      })
        .then((response) => response.json())
        .then((result) => {
          //Auth Token Failed
          if (result.AuthSuccess == 0) {
            this.$root.logout();
          } else {
            this.contacts = result.data?.map((e) => {
              e.checked =
                this.isSelectAll &&
                this.uncheckedMembers.map((e) => e.UserID).indexOf(e.UserID) ==
                  -1;
              e.class = "hover_item";
              e.checkdClass = "selected_item";
              
              if (this.checkedMember.some((u) => u.UserID == e.UserID)) {
                e.class = "disabled";
                console.log('--11--',e, e.FirstName)
                e.checked = true;
              }
              if (!this.activateGroupBtn && e.checked && !this.selectedMember?.checkedMember?.some((u) => u.UserID == e.UserID) && !this.selectedMember?.isSelectAll) {
                console.log('----', e,this.selectedMember?.checkedMember)
                e.class = "disabled";
              }
              if (!this.manageMemberSelect(e)) {
                console.log("3",e.FirstName, this.manageMemberSelect(e));
                e.disable_checkbox = true;
                if(!this.isSelectAll){
                  e.checked = false;
                }
              } else {
                //console.log( e.UserID, this.selectedMember?.uncheckedMember )
              }
              return e;
            });

            console.log(this.contacts);
            this.contactPage.pager = result.pager;
          }
          this.formsubmitwait = false;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    updateSelectMembers(e) {
      console.log("fd");
    },
    cancel() {
      console.log("ddd no");
    },

    closeDialog() {
      this.$emit("close-dialog");
      this.dialog = false;
    },
    addContactsToGroupFn() {
      this.activateGroupBtn = false;
      this.activateAddGroup = true;
      this.contacts = this.contacts.map((e) => {
        if (e.checked) {
          e.checkdClass = "disabled";
        }
        return e;
      });
      if(this.isSelectAll){
        this.$emit('added-to-group')
      }
    },
    addContactsToGroup() {
      const API_URL = process.env.VUE_APP_APIDOMAIN + "/";
      const req = {
        group_id: this.group.Id,
        selectedMember: this.checkedMember.map((e) => e.UserID),
        unselectedMember: this.uncheckedMembers.map((e) => e.UserID),
        isSelectAll: this.isSelectAll,
        ClientID: this.$root.userdetail.user[0].ClientID,
      };
      if (!this.isSelectAll && this.checkedMember.length == 0) {
        return;
      }
      this.loading = true;
      //  this.conversations = JSON.parse(item);
      fetch(`${API_URL}add_contacts_to_group`, {
        method: "POST",
        body: JSON.stringify(req),
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + this.token,
        },
      })
        .then((response) => response.json())
        .then((result) => {
          // this.contacts = result;
          this.dialog = true;
          this.activateAddGroup = false;
          this.showSuccess = true;
          this.removedContactCount = this.selectedContacts;
          setTimeout(() => {
            this.showSuccess = false;
          }, 3000);
          this.$emit("update-member", this.selectedContacts);
          this.reset();
          this.dialog = false;
          this.getContacts();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    reset() {
      this.loading = false;
      this.isSelectAll = false;
      this.indeterminate = false;
      this.selectedContacts = 0;
      this.selectedContactIds = [];
      this.uncheckedMembers = [];
      this.checkedMember = [];
    },
    //allow check for group member
    // if has groupid and not in checkedmemberarray or selectall not checked and not in unchekedmemberarray or selectall checked
    //fn to check if the contact that is member but allowed to be selected
    manageMemberSelect(e) {
      // console.log('dfdfs',e);
      // console.log('test-2',this.selectedMember?.checkedMember?.length);
      // console.log('test-02',this.selectedMember?.checkedMember?.some(
      //         (u) => u.UserID == e.UserID
      //       ));
      // console.log('eee',(e.Group_Id &&
      //     ((this.selectedMember?.checkedMember?.length > 0 &&
      //       this.selectedMember?.checkedMember?.some(
      //         (u) => u.UserID == e.UserID
      //       )) ||
      //       (!this.selectedMember?.isSelectAll &&
      //         this.selectedMember?.uncheckedMember?.length > 0 &&
      //         !this.selectedMember?.uncheckedMember?.some(
      //           (u) => u.UserID == e.UserID
      //         )) ||
      //       this.selectedMember?.isSelectAll)) ||
      //   !e.Group_Id)
      return (
        (e.Group_Id &&
          ((this.selectedMember?.checkedMember?.length > 0 &&
            this.selectedMember?.checkedMember?.some(
              (u) => u.UserID == e.UserID
            )) ||
            (!this.selectedMember?.isSelectAll &&
              this.selectedMember?.uncheckedMember?.length > 0 &&
              !this.selectedMember?.uncheckedMember?.some(
                (u) => u.UserID == e.UserID
              )) ||
            this.selectedMember?.isSelectAll)) ||
        !e.Group_Id
      );
    },
    selectMembers(e) {
      try {
        let event = e.e;
        const u = manageSingleChecked(
          event,
          e.item,
          "UserID",
          this.contacts,
          this.selectedContacts,
          this.isSelectAll,
          this.contactPage.pager.totalItems
        );
        this.selectedContacts = u.selectedCounts;
        this.indeterminate = u.indeterminate;
        this.isSelectAll = u.isSelectAll;
        if (this.selectedContacts > 0) {
          this.activateGroupBtn = true;
        } else {
          this.activateGroupBtn = false;
        }
        console.log(this.contacts);
        manageSelectedItems(event, e.item, "UserID", this.checkedMember);
        manageUnSelectedItems(event, e.item, "UserID", this.uncheckedMembers);
        console.log("pppp", this.selectedContactIds);
      } catch (e) {
        console.log(e);
      }
    },
    selectAllContacts(e) {
      this.isSelectAll = e.target.checked;
      this.indeterminate = false;
      this.checkedMember = [];
      this.uncheckedMembers = [];
      if (!e.target.checked) {
        this.selectedContactIds = [];
        this.selectedContacts = 0;
        this.activateGroupBtn = false;
        this.contacts = this.contacts.map((e) => {
          e.checked = false;
          e.checkdClass = "";
          e.class = "";
          return e;
        });
      } else {
        this.activateGroupBtn = true;
        this.selectedContacts = this.contactPage.pager.totalItems;
        this.contacts = this.contacts.map((e) => {
          console.log(e.FirstName, e, this.manageMemberSelect(e));
          if (this.manageMemberSelect(e)) {
            e.checked = true;
          }
          return e;
        });
        this.selectedContactIds = this.contacts.map((r) => r.id);
      }
    },
    searchMember(val) {
      this.searchMemberText = val;
      this.getContacts();
    },
    changePage(page) {
      this.contactPage.currentPage = page;
      this.getContacts();
    },
  },
  mounted() {
    console.log("------====--", this.checkedMember);
    this.getContacts();
  },
};
</script>
<style scoped lang="scss">

::v-deep .s2g-common-table {
  thead {
    tr {
      border-bottom: 2px solid #000;
    }
  }
}
</style>
