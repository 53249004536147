<template>
  <div class="s2gtable"> 
    <div
      class="d-flex justify-center search_container"
      :class="{ input_section: !thsearch && dynamicSearchHeight }"
      v-if="!thsearch"
    >
      <InfoPanel @close="showInfo = false" v-if="showInfo">
        <span>{{ infoMessage }}</span>
      </InfoPanel>
      <template v-if="state.openSearch && isGlobalSearch">
        <div ref="inputRef" class="position-relative search_bar">
          <span class="filter_icon position-absolute" @click="search"
            ><SearchIcon :variant="'white'"
          /></span>
          <button type="button" @click="clearInput" class="clear-btn">
            <svg class="default" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18.3642 7.05025L13.4144 12L18.3642 16.9497L16.95 18.364L12.0002 13.4142L7.05048 18.364L5.63627 16.9497L10.586 12L5.63627 7.05025L7.05048 5.63604L12.0002 10.5858L16.95 5.63604L18.3642 7.05025Z" fill="#7B8794"/>
            </svg>
            <svg class="hovered" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="3" y="3" width="18" height="18" rx="4" fill="#E0E0E0"/>
              <path d="M18.3642 7.05025L13.4144 12L18.3642 16.9497L16.95 18.364L12.0002 13.4142L7.05048 18.364L5.63627 16.9497L10.586 12L5.63627 7.05025L7.05048 5.63604L12.0002 10.5858L16.95 5.63604L18.3642 7.05025Z" fill="#7B8794"/>
            </svg>
          </button>
          <input
            class=""
            v-model="searchInput"
            placeholder="Search..."
            v-on:keyup.enter="search"
          />
        </div>
      </template>
    </div>
    <table class="table border-1 s2g-common-table" :class="{ 'disabled-table': disabledTable }">
      <thead>
        <tr>
          <th :class="{ inline_search: thsearch }"> 
            <div
              class="d-flex align-center"
              :class="{ col_disabled: disabledColumn?.first_col }"
            >
              <Checkbox
                :indeterminate="indeterminate"
                :value="isAllSelected"
                @change="emitSelectAll"
                :variant="isWhiteIcons?'white':''" 
                :class="{ col_disabled: !batchAction }"
              />
              <span class="common-icon" :class="{ col_disabled: !batchAction }">
                <DropdownFilter
                  :items="filterItems"
                  :isWhite="isWhiteIcons"
                  @item-selected="$emit('column-filter', { e: $event })"
                  :value="''"
                />
              </span>
              <span
                class="common-icon"
                :class="{
                  disabled: state.openSearch,
                  cursor_pointer: !state.openSearch,
                }"
                v-if="isGlobalSearch"
                ref="searchButton"
                @click="toggleSearch"
              > 
                <SearchIcon v-show="state.openSearch === false" :variant="isWhiteIcons?'white':''" />
              </span>
            </div>
          </th>
          <template v-for="(column, index) in headers" :key="column.key">
            <th
              v-if="index == headers.length - 1"
              :style="{ width: column.width }"
              style="max-width: 100px;"
              :class="[
                '',
                thsearch ? 'inline_search' : '',
                typeof disabledColumn === 'object' && typeof disabledColumn.col === 'object' && (disabledColumn.col ?? []).some(obj => obj === column.key)
                  ? 'col_disabled'
                  : '',
              ]"
            >

            <span v-if="index == 0">
                  <template v-if="showCount">
                    {{ totalItems }} {{ totalItems < 2 ? totalCountText1: totalCountText}}
                    <span v-if="selectedItemCount > 0">/{{ selectedItemCount }} Selected</span>
                  </template>
            </span>

              <div
                class="d-flex align-center justify-end"
                :class="{
                  'th_search align-center justify-space-between': thsearch,
                }"
              >
                <!---Search-->
                <span v-if="index == 0">
                  <template v-if="showCount">
                    <!-- {{ totalItems }} {{ totalCountText}}
                    <span v-if="selectedItemCount > 0">/{{ selectedItemCount }} Selected</span> -->
                  </template>
                  <template v-else>
                    <!---Filter-->
                      <span
                        class="cursor_pointer"
                        v-if="column.isFilter && column.filterItems?.length > 0"
                      >
                        <DropdownFilter
                          :items="column.filterItems"
                          :isWhite="isWhiteIcons"
                          :value="column.title"
                          @item-selected="
                          
                            $emit('column-filter', { e: $event, column })
                          "
                        />
                      </span>
                      <span v-else>{{ column.title }}</span>
                  </template>
                </span>
                <span v-else class="mr-70">
                    <span
                        class="cursor_pointer"
                        v-if="column.isFilter && column.filterItems?.length > 0"
                      >
                        <DropdownFilter
                          :items="column.filterItems"
                          :isWhite="isWhiteIcons"
                          :value="column.title"
                          @item-selected="
                            $emit('column-filter', { e: $event, column })
                          "
                        />
                      </span>
                      <span v-else>{{ column.title }}</span>
                </span>
                <div
                  v-if="
                    thsearch && index == 0 && state.openSearch && isGlobalSearch
                  "
                  ref="searchInput2"
                  class="search_bar th_search_bar"
                  :class="{ item_selected_width: selectedItemCount > 0 }"
                >
                  <span class="filter_icon position-absolute" @click="search"
                    ><SearchIcon :variant="'white'"
                  /></span>
                  <button type="button" @click="clearInput" class="clear-btn">
                    <svg class="default" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M18.3642 7.05025L13.4144 12L18.3642 16.9497L16.95 18.364L12.0002 13.4142L7.05048 18.364L5.63627 16.9497L10.586 12L5.63627 7.05025L7.05048 5.63604L12.0002 10.5858L16.95 5.63604L18.3642 7.05025Z" fill="#7B8794"/>
                    </svg>
                    <svg class="hovered" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect x="3" y="3" width="18" height="18" rx="4" fill="#E0E0E0"/>
                      <path d="M18.3642 7.05025L13.4144 12L18.3642 16.9497L16.95 18.364L12.0002 13.4142L7.05048 18.364L5.63627 16.9497L10.586 12L5.63627 7.05025L7.05048 5.63604L12.0002 10.5858L16.95 5.63604L18.3642 7.05025Z" fill="#7B8794"/>
                    </svg>
                  </button>
                  <input
                    class=""
                    v-model="searchInput"
                    placeholder="Search..."
                    v-on:keyup.enter="search"
                  />
                </div>
                
                <!---Search-->
                <span
                  class="cursor_pointer"
                  v-if="isReload"
                  @click="$emit('refresh')"
                >
                  <ResetIcon :variant="isWhiteIcons?'white':''" />
                </span>
              </div>
            </th>
            <th
              :class="{col_disabled: typeof disabledColumn === 'object' && typeof disabledColumn.col === 'object' && (disabledColumn.col ?? []).some(obj => obj === column.key)}"
              :style="{ width: column.width }"
              v-else
            >
              <div
                class="d-flex"
                :class="{
                  'th_search align-center justify-space-between': thsearch,
                }"
              > 
                <div
                  class="filter-cell"
                  v-if="column.isFilter && column.filterItems?.length > 0"
                >
                  <DropdownFilter
                    :items="column.filterItems"
                    :isWhite="isWhiteIcons"
                    :value="column.title"
                    @item-selected="
                      $emit('column-filter', { e: $event, column })
                    "
                  />
                  {{column.title}}
                </div>
                <div
                  v-else
                >
                  <!---Search-->

                  <span v-if="index == 0">
                    <template v-if="showCount">{{ totalItems }} {{ totalItems < 2 ? totalCountText1: totalCountText}}
                      <span v-if="selectedItemCount > 0">/{{ selectedItemCount }} Selected</span>
                    </template>
                    <template v-else>
                      {{ column.title }}
                    </template>
                  </span>
                  <span v-else>{{ column.title }}</span>
                </div>
                <div
                  ref="searchInput2"
                  v-if="
                    thsearch && index == 0 && state.openSearch && isGlobalSearch
                  "
                  class="position-relative search_bar th_search_bar"
                  :class="{ item_selected_width: selectedItemCount > 0 }"
                >
                  <span class="filter_icon position-absolute"
                    ><SearchIcon :variant="'white'"
                  /></span>
                  <button type="button" @click="clearInput" class="clear-btn">
                    <svg class="default" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18.3642 7.05025L13.4144 12L18.3642 16.9497L16.95 18.364L12.0002 13.4142L7.05048 18.364L5.63627 16.9497L10.586 12L5.63627 7.05025L7.05048 5.63604L12.0002 10.5858L16.95 5.63604L18.3642 7.05025Z" fill="#7B8794"/>
            </svg>
            <svg class="hovered" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="3" y="3" width="18" height="18" rx="4" fill="#E0E0E0"/>
              <path d="M18.3642 7.05025L13.4144 12L18.3642 16.9497L16.95 18.364L12.0002 13.4142L7.05048 18.364L5.63627 16.9497L10.586 12L5.63627 7.05025L7.05048 5.63604L12.0002 10.5858L16.95 5.63604L18.3642 7.05025Z" fill="#7B8794"/>
            </svg>
                  </button>
                  <input
                    v-model="searchInput"
                    placeholder="Search..."
                    v-on:keyup.enter="search"
                  />
                </div>
                <!---Search-->
              </div>
            </th>
          </template>
          <th  v-if="isRemove" aligh="right">
              <div class="remove_section" style="text-align: right;">
                 <!--  <slot name="is_remove_content"></slot> -->
                  <div class="d-flex align-center justify-end pr-2">
                    <span class="remove-data mx-2">Remove</span>
                    <QuestionMark v-if="!showInfo" @click="showInfo = true" class="w-24" />
                    <QuestionMarkSolid v-if="showInfo" class="w-24" />
                  </div>
                </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in items"
          :key="index"
          :class="[
            'hover_item',
            'disabled_row' ? item.loading : '',
            item.class,
            item.singleRemoveClass,
            item.checked ? item.checkdClass : '',
            item.disable_checkbox || isDisabledAllCheckBox?'disabled_row':''
          ]"
        >  
          <td> 
            <div class="d-flex align-center">
              <Checkbox
                :disabled="isDisabledAllCheckBox || item.disable_checkbox"
                class="select_single"
                :variant="isWhiteIcons?'white':''" 
                :value="item.checked"
                @change-checked="($event) => toggleSelect($event, item)"
              />
              <span class="indexing">
              {{ (page) + (index+1)  }}. 
              </span>
            </div>
          </td>
          <slot name="table_body" :item="{ item, index }"></slot>
        </tr>
      </tbody>
    </table>
  </div>
  
</template>
<script setup>
/**
 * @vue-event {e, item} column-filter - emit when filter from the dropdown is changes
 * @vue-event {e, item} toggle-row-select - emit when checkbox is checked/unchecked
 * @vue-event {e} select-all - emit when all checkbox is checked/unchecked
 * @vue-event {e} refresh - emit when reload icon in clicked
 */
import {
  ref,
  reactive,
  computed,
  onMounted,
  onBeforeUnmount,
  defineProps,
  defineEmits,
  watch,
} from "vue";
import SearchIcon from "../../components/icons/SearchIcon";
import ResetIcon from "../../components/icons/ResetIcon";
import Remove from "../../components/icons/Remove";
import QuestionMark from "../../components/icons/QuestionMark";
import QuestionMarkSolid from "../../components/icons/QuestionMarkSolid";
import DropdownFilter from "../../components/common/DropdownFilter";
import InfoPanel from "@/components/common/InfoPanel.vue";
import Checkbox from "@/components/common/Checkbox.vue";

// Define props
const props = defineProps({
  // global filter
  filterItems: {
    type: Array,
    default: () => [],
  },
  //header json
  /**
   * example {
        key: "type",
        title: "Type",
        width: "30%",
        isFilter: true,// optional
        // optional
        filterItems: [
          {label:'Lable 1', value: 'Value 1'},
          {label:'Lable 2', value: 'Value 2'},
        ],
      },
   * */
  headers: {
    type: Array,
    default: () => [],
  },
  //table data
  items: {
    type: Array,
    default: () => [],
  },

  disabledColumn: {
    type: Object,
    default: () => {},
  },

  indeterminate: {
    type: Boolean,
    default: false,
  },
  thsearch: {
    type: Boolean,
    default: false,
  },
  showCount: {
    type: Boolean,
    default: false,
  },
  isWhiteIcons: {
    type: Boolean,
    default: false,
  },
  isReload: {
    type: Boolean,
    default: true,
  },
  totalItems: {
    type: Number,
    default: null,
  },
  selectedItemCount: {
    type: Number,
    default: null,
  },
  isGlobalSearch: {
    type: Boolean,
    default: true,
  },
  isRemove: {
    type: Boolean,
    default: false,
  },
  totalCountText: {
    type: String,
    default: "Contacts",
  },
  totalCountText1: {
    type: String,
    default: "Contact"
  },
  isSelectGlobalCheckBox: {
    type: Boolean,
    default: false,
  },
  isDisabledAllCheckBox: {
    type: Boolean,
    default: false,
  },
  dynamicSearchHeight: {
    type: Boolean,
    default: true,
  },
  infoMessage: {
    type: String,
    default: "",
  },
  searchText: {
    type: String,
    default: "",
  },
  page: {
    type: Number,
    default: 0,
  },
  disabledTable :{
    type:Boolean,
    default:false,
  },
  batchAction :{
    type:Boolean,
    default:true
  }
});
// Setup component state
const state = reactive({
  openSearch: false,
});
const searchInput = ref(null);
const inputRef = ref(null);
const selectedItem = ref(0);
const searchInput2 = ref(null);
const searchButton = ref(null);
const isAllSelected = ref(false);
const showInfo = ref(false);
// Define computed properties
const isActiveGroupSection = computed(() => props.activateGroups);

// Define methods
function toggleSelect(e, item) {
  item.checked = e.target.checked;
  // Logic to handle toggle selection
  emit("toggle-row-select", { e, item });
  if (e.target.checked) {
    selectedItem.value = selectedItem.value + 1;
  } else {
    selectedItem.value = selectedItem.value - 1;
  }
  // setTimeout((e) => {
  //   const u = props.items.filter((e) => e.checked).length;
  //   selectedItem.value = u;
  // }, 100);
}

function search(event) {
  // console.log("search", searchInput.value);
  emit("search", searchInput.value);
}

function emitSelectAll($event) {
  emit("select-all", $event);

  if ($event.target.checked) {
    isAllSelected.value = true;
    selectedItem.value = props.totalItems;
    console.log("uiuu", selectedItem.value);
  } else {
    if (isAllSelected.value) {
      selectedItem.value = 0;
    }
    isAllSelected.value = false;

    // setTimeout((e) => {
    //   const u = props.items.filter((e) => e.checked).length;
    //   selectedItem.value = u;
    // }, 100);
  }
}
function handleClickOutside(event) {
  if (!state.openSearch) {
    return;
  }
  try {
    //console.log(props.thsearch,searchInput2.value[0], searchInput.value)
    if (!props.thsearch) {
      if (
        !inputRef.value.contains(event.target) &&
        !searchButton.value.contains(event.target)
      ) {
        
        state.openSearch = false;
        emit('close-search', true)
      }
    } else {
      if (
        !searchInput2.value[0].contains(event.target) &&
        !searchButton.value.contains(event.target)
      ) {
        state.openSearch = false;
        emit('close-search', true)
      }
    }
  } catch (e) {
    console.log(e, searchInput);
  }
}
function toggleSearch() {
  showInfo.value = false;
  state.openSearch = true;
  emit('open-search', true)
}
function clearInput() {
  searchInput.value = "";
  search();
}

// Lifecycle hooks
onMounted(() => {
  document.addEventListener("click", handleClickOutside);
});

onBeforeUnmount(() => {
  document.removeEventListener("click", handleClickOutside);
});
watch(
  () => props.isSelectGlobalCheckBox,
  (newValue, oldValue) => {
    console.log("New value:", newValue, "Old value:", oldValue);
    isAllSelected.value = newValue;
    if (newValue == false) {
      selectedItem.value = 0;
    }
  },
  { immediate: true }
);
watch(
  () => props.searchText,
  (newValue, oldValue) => {
    searchInput.value = newValue;
    console.log("New search:", searchInput);
  },
  { immediate: true }
);
// Emits
const emit = defineEmits([
  "column-filter",
  "toggle-row-select",
  "select-all",
  "refresh",
  "show-info",
]);
</script>

<style lang="scss">

.s2gtable {
    width: 100%;
    table {
        vertical-align: middle;
    }
    thead {
        border-bottom: 2px solid #000;
        th {
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            background: transparent;
            padding: 2px 8px 10px;
            &:nth-child(1) {
                width: 88px;
            }
            &:last-child {
                padding-right: 0;
            }
            &.inline_search {
                padding-bottom: 14px;
                vertical-align: middle;
            }
            .filter-cell { 
              gap: 0;
            }
        }
    }
    tbody {
        tr {
            height: 50px;
            border-bottom: 1px solid #BDBDBD;
            td {
                padding: 8px;
                min-height: 50px;
                background: transparent !important;
                .filter-cell { 
                  margin-left: -8px;
                }
            }
            &.hover_item {
                &:hover {
                  background: #eaf2fc;
                }
            }
        }
    }
    .table-actions{
        gap: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    .filter-cell {
      display: grid;
      grid-template-columns: 24px 1fr;
      align-items: center;
      gap: 8px;
    }
    .member_selected {
      background-color: #eaf2fc;
    }
    .indexing{ 
        text-align: right;
        display: flex;
        width: 48px;
        justify-content: end;
    }
    .common-icon {
      width: 24px;
      height: 24px;
      background: transparent !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .fixed-length-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }
    
}

 

.search_bar {
  border: 1px solid #bdbdbd;
  border-radius: 8px;
  background: #fff;
  height: 44px;
  width: 444px;
  padding-left: 50px;
  padding-right: 7px;
 
  .filter_icon {
    top: 9px;
    left: 9px;
    cursor: pointer;
    width: 10px;
  }
  input {
    border: none;
    height: 100%;
    width: 100%;
    font-size: 15px;
    line-height: 16px;
    &:focus,
    &:focus-visible {
      border: none;
      outline: none;
    }
  }
  

  &.th_search_bar {
    width:  260px;
    height: 34px;
    position: absolute;
    right: 30px;
    border-radius: 4px !important;
    top: 24px;
    .filter_icon {
      top: 3px;
      left: 8px;
    }
    input {
      border: none;
      height: 100%;
      width: 100%;
      font-size: 15px;
      line-height: 16px;
    font-weight: 400;    
  }
    .clear-btn {
      top: 3px;
      right: 4px;
    }
  }
}
.search_container {
  margin-bottom: 10px;
  display: flex;
  align-items: center

}
.disabled_row {
  opacity: 0.6;
  pointer-events: none;
}
.th_search {
  line-height: 1.5;
}
 
.input_section {
  height: 50px;
}
 
 
.col_disabled {
  opacity: 0.5;
  pointer-events: none;
}
.clear-btn {
  overflow: hidden;
  width: 24px;
  height: 24px;
  padding: 0;
  margin: 0;
  border: 1px solid transparent;
  border-radius: inherit;
  cursor: pointer;
  opacity: 1;
  position: absolute;
  left: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  pointer-events: all;
  background: none;
  font-size: 26px;
  right: 7px;
  top: 8px;
  line-height: 1;
  left: initial;
}
.cursor_pointer {
  cursor: pointer;
}
.disabled-table{
  pointer-events: none; 
  opacity: .4;
  /* background: #f2f2f2; 
  opacity: .6;
  position: absolute;
  left:0px;
  top:0px;
  width: 100%;
  height: 100%; */
}
.mr-70 {
  margin-right: 70px;
}
 
</style>
