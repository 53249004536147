<template>
    <div>
        <v-menu :disabled="disabled" v-model="menu" :close-on-content-click="false" offset-y>
            <template v-slot:activator="{ props }">
                <div v-bind="props" style="text-align:center">
                    <SettingDots/>
                </div>
            </template>
            <v-card class="edit-settings-modal">
                <v-card-title class="modal-title-con">
                    <h5 class="modal-title">{{content.Title}}</h5>
                </v-card-title>
                        <div class="edit-container">
                            <div class="edit-row">
                                <div class="edit-item">
                                    Operators
                                    <!-- <DurationIcon class="metadata-popup-icons"/>For {{messageMetadata.duration}} -->
                                </div>
                                <div class="edit-item">
                                    <div>{{data.operator}}</div>
                                </div>
                            </div>
                            
                            <div class="edit-row">
                                <div class="edit-item">
                                  <div>Contacts</div> 
                                </div>
                                <div class="edit-item">
                                    <div>{{data.contact}}</div>
                                </div>
                            </div>
                            
                            <div class="edit-row">
                                <div class="edit-item">
                                    Groups
                                </div>
                                <div class="edit-item">
                                    <div>{{data.group}}</div>
                                </div>
                            </div>

                            <div class="edit-row">
                                <div class="edit-item">
                                    Messages
                                </div>
                                <div class="edit-item">
                                    <div>{{data.message}}</div>
                                </div>
                            </div>

                        </div>  

                        
                        <!-- <div class="edit-links">
                            <span style="width:100%; padding-left: 33px;font-size: 16px;
                                        line-height: 2;
                                        color: #7B8794;">Last Update: {{ new Date(content.Modified).toLocaleString('en-US', last_updated_time_options) }}</span>
                            <router-link class="action-button" :to="{ name: 'ComposeMessage', query:{id: content.ContentID, settings :1 } }">
                                <EditIcon/>
                            </router-link>
                        </div> -->
                
                <!-- <v-card-actions class="justify-end">
                    <router-link class="action-button" :to="{ name: 'ComposeMessage', query:{id: content.message_settings.ContentID, settings :1 } }">
                      <EditIcon/>
                    </router-link>
                </v-card-actions> -->
            </v-card>


        </v-menu>
    </div>
  </template>
  
  <script setup>
    import { ref, defineProps, defineEmits, watch, computed, onMounted} from 'vue';
    import SettingDots from '../icons/message_settings/SettingDots.vue';
    import EditIcon from '../icons/EditIcon.vue'
    import DurationIcon  from '../icons/message_settings/DurationIcon.vue';
    import CalendarIcon  from '../icons/message_settings/CalendarIcon.vue';
    import RepeatIcon  from '../icons/message_settings/RepeatIcon.vue';
    import TimeIcon from '../icons/message_settings/TimeIcon.vue';
    let props = defineProps(['content']);
    let emits = defineEmits(['item-selected']);
    let menu = ref(false);
    let disabled = ref(props.disabled);
    let token = localStorage.getItem("tknds") || ''
    let data = ref({});



    onMounted(() => {
        getData();
    })

    // watch works directly on a ref
    watch(menu, async (newVal, oldVal) => {
        if (newVal) {
            getData();
        }
    })


    function getData(){
        let url = `${process.env.VUE_APP_APIDOMAIN}/getcountforclient?ClientID=${props.content.ClientID}`;
        if(menu.value){
         fetch(url, { 
                method: 'GET', 
                headers: {
                    "content-type": "application/json",
                    "Authorization": "Bearer "+token
                } 
                })                      
                .then(response => response.json())
                .then(async (result) => {
                    //Auth Token Failed      
                    if(result.AuthSuccess == 0){
                        //this.serverError = result.message
                        this.$root.logout();                      							           
                    } else {
                        data.value = result.data;
                }                 
            }) 
        }
    }


    let last_updated_time_options = {
        year: 'numeric',
        month: 'short', // 'long' for full month name
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true, // Use 12-hour time format
    }



  </script>
<style lang="scss" scoped>
    .metadata-popup-icons{
        width: 24px;
        height: 24px;
    }
    .card-text-con{
        padding: 0px !important;
    }
    .v-row > div{
        padding-top:10px;
    }
    .dropdown-btn{
        box-shadow: none !important;
        width: 100%;
        text-align: left !important;
        &::before{
            display: none !important;
        }
        &:hover, &:focus{
            background: #fff;
            box-shadow: none;
        }
        
        .v-btn__content{
            text-align: left !important;
            justify-content: space-between !important;
            align-items: center !important;
            color:  #828282 !important;
            font-family: 'Roboto' !important;
            font-size: 16px !important;
            font-style: normal;
            font-weight: 400;
            line-height: 16px !important; 
            text-transform: none !important;
            
            .v-icon{
                font-size: 26px !important;
                color: #7B8794 !important;
            }
        }
        .v-list-item{
            color:#828282 !important;
            font-family: 'Roboto' !important;
            font-size: 16px !important;
            font-style: normal;
            font-weight: 400;
            line-height: 16px !important;
        }
    }
    .edit-settings-modal {
        width: 430px;
        height: 250px !important;
        padding: 24px 10px 10px 23px;
        display: flex;
        flex-direction: column;
        & .modal-title-con {
            padding: 0;
            
            margin-bottom: 28px;
            & .modal-title {
                font-size: 16px;
            line-height: 1;
            font-weight: 500;
            color: #333333;
            max-width: 328px;
            overflow: hidden;
            text-overflow: ellipsis;
            }
        }
        & .edit-container {
            display: flex;
            flex-direction: column;
            gap: 24px;
            & .edit-row {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                &.edit-row-full {
                    grid-template-columns: 1fr;
                }
            }
            & .edit-item {
            font-size: 16px;
            line-height: 1;
            color: #828282;
            font-weight: 500;
            display: flex;
            align-items: center;
            gap: 9px;
        }
            
        }
        & .edit-links {
                margin-top: auto;
                display: flex;
                justify-content: flex-end
            }
        
    }
</style>