<template>
    <AlertDialog v-model="dialog" @closed="closeModal" title="Attention" headerColor="red">
        <div class="deactivated-modal-content">
        <p>Your account has been deactivated.</p>
        <p>To add, edit or delete
            <span v-if="props.context" v-text="`${props.context}, `"></span>
            <span v-else>, </span>
            update your Billing.</p>
        <p>If you need assistance, contact Sig2's Support.</p>
    </div>
    </AlertDialog>
</template>

<script setup>
import { defineEmits, defineProps, ref } from 'vue';
import AlertDialog from './AlertDialog.vue';

let props = defineProps(['value', 'title', 'context'],)
const emits = defineEmits(['closed']);

const dialog = ref(false)
    
function closeModal(){
    dialog.value = false;
    emits('closed');
}
</script>

<style scoped> 
.deactivated-modal-content {
    margin-bottom: 59px;
    p {
        line-height: 30px;
        font-size: 14px;
        font-weight: 400;
    }
}
</style>